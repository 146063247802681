import m from "mithril";

// Modified from https://codepen.io/linrock/pen/nMadjQ

const NUM_CONFETTI = 100;
const COLORS = [
  [255, 0, 0],
  [0, 255, 128],
  [0, 0, 255],
  [255, 128, 0],
  [128, 0, 255],
];
const PI_2 = 2 * Math.PI;

const range = (a: number, b: number) => {
  return (b - a) * Math.random() + a;
};

export const ConfettiCanvas: m.ClosureComponent<{}> = () => {
  let canvas: HTMLCanvasElement,
    context: CanvasRenderingContext2D,
    w: number,
    h: number,
    confetti: Confetti[],
    isAnimating: boolean;

  const drawCircle = (x: number, y: number, r: number, style: string) => {
    context.beginPath();
    context.arc(x, y, r, 0, PI_2, false);
    context.fillStyle = style;
    return context.fill();
  };

  class Confetti {
    style = [0, 0, 0];
    rgb = "";
    r = 0;
    r2 = 0;
    opacity = 0;
    dop = 0;
    x = 0;
    y = 0;
    xmax = 0;
    ymax = 0;
    vx = 0;
    vy = 0;

    constructor() {
      this.style = COLORS[~~range(0, COLORS.length)];
      this.rgb = `rgba(${this.style[0]},${this.style[1]},${this.style[2]}`;
      this.r = ~~range(2, 6);
      this.r2 = 2 * this.r;
      this.replace();
    }
    replace() {
      this.opacity = 0;
      this.dop = 0.03 * range(1, 4);
      this.x = range(-this.r2, w - this.r2);
      this.y = range(-20, h - this.r2);
      this.xmax = w - this.r;
      this.ymax = h - this.r;
      this.vx = range(-2, 2);
      return (this.vy = 1.7 * this.r + range(-1, 1));
    }
    draw() {
      var ref;
      this.x += this.vx;
      this.y += this.vy;
      this.opacity += this.dop;
      if (this.opacity > 1.3) {
        this.opacity = 1.3;
        this.dop *= -1;
      }
      if (this.opacity < 0 || this.y > this.ymax) {
        this.replace();
      }
      if (!(0 < (ref = this.x) && ref < this.xmax)) {
        this.x = (this.x + this.xmax) % this.xmax;
      }
      return drawCircle(this.x, this.y, this.r, `${this.rgb},${this.opacity})`);
    }
  }

  const doAnimation = () => {
    if (isAnimating) {
      window.requestAnimationFrame(doAnimation);
      context.clearRect(0, 0, w, h);
      for (let c of confetti) {
        c.draw();
      }
    }
  };

  return {
    view() {
      return m(".confetti", { style: { width: "100%", height: "100%" } }, m("canvas"));
    },
    oncreate(vnode) {
      canvas = vnode.dom.querySelector("canvas") as HTMLCanvasElement;
      context = canvas.getContext("2d") as CanvasRenderingContext2D;
      const rect = vnode.dom.getBoundingClientRect();
      w = canvas.width = rect.width;
      h = canvas.height = rect.height;
      isAnimating = true;

      confetti = [];
      for (let i = 0; i < NUM_CONFETTI; i++) {
        confetti.push(new Confetti());
      }
      doAnimation();
    },
    onremove() {
      isAnimating = false;
    },
  };
};
