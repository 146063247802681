import m from "mithril";

import { accountState } from "../account";
import { blogOrigin } from "../config";
import { Icon20, IconSVG } from "../icon";
import { logEvent } from "../log-event";
import { navState } from "./nav-state";

export const NavLogo: m.Component = {
  view() {
    return m(".nav-logo", [
      m(m.route.Link, { href: "/", "aria-label": "Cuttle home" }, [
        m(
          ".nav-logo-image",
          {
            // Because the link has aria-label, the image doesn't need an alt
            // attribute, and should be ignored by screen readers.
            "aria-hidden": "true",
          },
          m(IconSVG, { icon: "cuttle_logo" })
        ),
      ]),
    ]);
  },
};

export const HamburgerMenu: m.Component<{
  hamburgerExtra?: () => m.Children;
}> = {
  view({ attrs: { hamburgerExtra } }) {
    const closeHamburger = () => {
      navState.isHamburgerOpen = false;
      // Return true so we can use this as an onclick handler on `a href`s.
      return true;
    };

    const isLoggedIn = accountState.loggedInUser !== undefined;

    let mCommunity;

    if (accountState.loggedInUser?.subscription.plan !== "K-12 Education") {
      mCommunity = [
        m(
          "a.nav-link",
          {
            href: "https://www.facebook.com/groups/cuttle",
            target: "_blank",
            onclick: closeHamburger,
          },
          "Facebook Group"
        ),
        m(
          "a.nav-link",
          { href: "/discord", target: "_blank", onclick: closeHamburger },
          "Discord Server"
        ),
      ];
    }

    let mContents;

    if (navState.isHamburgerOpen) {
      let mExtra;
      if (hamburgerExtra) {
        mExtra = [m(".hamburger-menu-separator"), hamburgerExtra()];
      }

      mContents = m(".hamburger-menu-contents", [
        m(".hamburger-nav-open", [
          m(NavLogo),
          m(".modal-x", { onclick: closeHamburger }, m(Icon20, { icon: "x" })),
        ]),

        m(".hamburger-links", [
          isLoggedIn
            ? m(
                m.route.Link,
                {
                  class: "nav-link",
                  href: "/dashboard",
                },
                "My Projects"
              )
            : null,
          m(m.route.Link, { class: "nav-link", href: "/templates" }, "Templates"),
          m(m.route.Link, { class: "nav-link", href: "/explore" }, "Explore"),
          m(m.route.Link, { class: "nav-link", href: "/learn/video-tutorials" }, "Learn"),
          m("a.nav-link", { href: blogOrigin }, "Blog"),

          m(
            m.route.Link,
            {
              class: "nav-link",
              href: "/pricing",
              onclick: () => logEvent("clicked pricing", { from: "nav" }),
            },
            "Pricing"
          ),
          mCommunity,

          mExtra,
        ]),
      ]);
    }

    return m(".hamburger-nav", [
      m(".nav-links-container", [
        m(
          ".hamburger-icon",
          { onclick: () => (navState.isHamburgerOpen = true) },
          m(Icon20, { icon: "hamburger" })
        ),

        mContents,
      ]),
    ]);
  },
};
