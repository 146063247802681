import m from "mithril";
import { TemplatesSection } from "../templates";
import { genOrigin } from "../shared/config";

class LandingTemplatesState {
  private projectIds: string[] | "loading" | undefined;

  private async fetch() {
    this.projectIds = "loading";
    const response = await window.fetch(genOrigin + "/trending.json");
    const data = await response.json();
    this.projectIds = data.projectIds;
    m.redraw();
  }

  get() {
    if (!this.projectIds) {
      this.fetch();
      return "loading";
    }
    return this.projectIds;
  }
}
const landingTemplatesState = new LandingTemplatesState();

export const LandingTemplates: m.Component<{}> = {
  view() {
    const projectIds = landingTemplatesState.get();
    if (projectIds === "loading") return null;

    return m(TemplatesSection, { projectIds });
  },
};
