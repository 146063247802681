import m from "mithril";

import { API } from "./api-types";
import { apiOrigin } from "./config";

export interface APIRequestOptions {
  timeout?: number;
  skipRedraw?: boolean;
}

export const apiRequest = async <K extends keyof API>(
  requestName: K,
  body: Parameters<API[K]>[0],
  options?: APIRequestOptions
): Promise<{ success: false; message: string } | ({ success: true } & ReturnType<API[K]>)> => {
  const url = apiOrigin + "/api/v1/" + requestName;

  let signal: AbortSignal | undefined;
  let timeoutId: number | undefined;
  if (options && options.timeout && options.timeout > 0) {
    const controller = new AbortController();
    signal = controller.signal;
    timeoutId = window.setTimeout(() => controller!.abort(), options.timeout);
  }

  let response: Response;
  try {
    response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json; charset=utf-8" },
      credentials: "include",
      body: JSON.stringify(body),
      signal,
    });
    clearTimeout(timeoutId);
  } catch (error) {
    throw new Error(`[Network] (${url}) ${error}`);
  }

  // Fetch will only throw in case of a network error (such as being offline).
  // We need to make sure the server responded with an ok status code before
  // proceeding.
  if (!response.ok) {
    throw new Error(`[Server] (${url}) ${response.statusText} (${response.status})`);
  }

  // Parse the response as JSON.
  try {
    const responseJson = await response.json();
    console.log("[API]", requestName, body, responseJson);

    // Some API requests need to be followed by a UI redraw. This was the
    // default behavior when we were using `m.request()`. To avoid introducing
    // new bugs, we keep this default but add an opt-out.
    if (!options?.skipRedraw) {
      m.redraw();
    }

    return responseJson;
  } catch (error) {
    throw new Error(`JSON Parse failed (${url}) ${error}`);
  }
};

/**
 * Like `apiRequest` except we assume it's successful and throw an error if not.
 */
export const apiRequestSuccess = async <K extends keyof API>(
  requestName: K,
  body: Parameters<API[K]>[0],
  options?: APIRequestOptions
): Promise<{ success: true } & ReturnType<API[K]>> => {
  const responseData = await apiRequest(requestName, body, options);
  if (responseData.success) {
    return responseData;
  }
  console.log("[API] Error", responseData);
  throw new Error("[API] Error: " + responseData.message);
};
