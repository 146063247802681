import m from "mithril";
import { updateHead } from "./util";
import { SiteLayout } from "./shared/site-layout/site-layout";

export const ResetPasswordSentPage: m.Component<{}> = {
  view() {
    updateHead({
      title: "Cuttle - Reset Password",
    });

    return m(SiteLayout, { mainClass: "center-wrapper" }, [
      m(
        "p",
        "You will receive an email that contains further instructions for resetting your password. 📬"
      ),
    ]);
  },
};
