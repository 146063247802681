import m from "mithril";
import { apiRequestSuccess } from "./shared/api-request";
import { projectCardState } from "./shared/projects-card-state";
import { ProjectCardData } from "./shared/api-types";
import { TemplateCard } from "./templates";
import { updateHead } from "./util";
import { TagStrip } from "./tag-strip";

// ============================================================================
// State
// ============================================================================

class DashboardTagState {
  private tagsAndProjects:
    | {
        tags: { tagId: number; tagName: string; slug: string }[];
        projects: ProjectCardData[];
      }
    | "loading"
    | undefined;

  private async fetchTags() {
    this.tagsAndProjects = "loading";
    const { tags, projects } = await apiRequestSuccess("getTagsAndProjects", {}); // Todo: changed projects to projectCards
    projectCardState.rememberProjectCards(projects);
    this.tagsAndProjects = { tags, projects };
    m.redraw();
  }

  getTagsAndProjects() {
    if (!this.tagsAndProjects) {
      this.fetchTags();
      return "loading";
    }
    return this.tagsAndProjects!;
  }
}

export const dashboardTagState = new DashboardTagState();

// ============================================================================
// View
// ============================================================================

interface DashboardTagAttrs {
  tagSlug: string;
}

export const DashboardTag: m.Component<DashboardTagAttrs> = {
  view({ attrs: { tagSlug } }) {
    const tagsAndProjects = dashboardTagState.getTagsAndProjects();
    if (tagsAndProjects === "loading") {
      return null; // Todo: add spinnner?
    }

    const { tags, projects } = tagsAndProjects;

    let projectsWithTag = projects;
    let pageTitle = "All Templates";
    if (tagSlug) {
      const tag = tags.find((tag) => {
        return tag.slug === tagSlug;
      });

      if (!tag) {
        //Todo: add page not found if null
        return null;
      }

      pageTitle = tag.tagName;

      const tagId = tag.tagId;

      projectsWithTag = projects.filter((project) => {
        return project.tags.indexOf(tagId) !== -1;
      });
    }

    updateHead({
      title: `${pageTitle} - Cuttle`,
    });

    projectsWithTag.sort((a, b) => {
      const aDate = new Date(a.firstMadePublicDate!);
      const bDate = new Date(b.firstMadePublicDate!);
      return bDate.getTime() - aDate.getTime();
    });

    const mCards = projectsWithTag.map((project) => {
      const projectId = project.projectId;
      return m(TemplateCard, {
        projectId,
        key: projectId,
      });
    });

    return m("div", [
      //
      m(TagStrip),
      m("h2", pageTitle),
      m(".card-grid", [mCards]),
    ]);
  },
};
