import m from "mithril";

import { accountState } from "./shared/account";
import { apiRequest } from "./shared/api-request";
import { Icon20 } from "./shared/icon";

const clamp = (value: number, min: number, max: number) => {
  return Math.min(Math.max(value, min), max);
};

export const AvatarUploadButton: m.ClosureComponent<{}> = () => {
  let uploadedBlobUrl: string | undefined = undefined;
  let minX = 0;
  let minY = 0;
  let maxX = 100;
  let maxY = 100;
  let imgNaturalWidth: number;
  let imgNaturalHeight: number;
  let imgResizedWidth = 400;
  let imgResizedHeight = 400;
  let imageLoaded = false;
  const minSize = 50;
  return {
    onupdate(vnode) {},
    view(vnode) {
      const chooseImage = async (event: Event) => {
        const file = (event.target as HTMLInputElement).files?.[0];

        if (file) {
          const url = URL.createObjectURL(file);
          uploadedBlobUrl = url;
          const img = new Image();
          img.addEventListener("load", () => {
            imgNaturalWidth = img.naturalWidth;
            imgNaturalHeight = img.naturalHeight;
            let imgAspectRatio = imgNaturalWidth / imgNaturalHeight;
            imgResizedWidth = 400;
            imgResizedHeight = imgResizedWidth / imgAspectRatio;

            if (imgResizedWidth < imgResizedHeight) {
              minY = (imgResizedHeight - imgResizedWidth) / 2;
              minX = 0;
              maxX = imgResizedWidth;
              maxY = minY + imgResizedWidth;
            } else {
              minY = 0;
              minX = (imgResizedWidth - imgResizedHeight) / 2;
              maxX = minX + imgResizedHeight;
              maxY = imgResizedHeight;
            }
            imageLoaded = true;
            m.redraw();
          });
          img.src = uploadedBlobUrl;
        } else {
          uploadedBlobUrl = undefined;
        }
      };

      let mPopup;

      const startHandleDrag = (onPointerMove: (pointerMoveEvent: PointerEvent) => void) => {
        const moveHandler = (pointerMoveEvent: PointerEvent) => {
          onPointerMove(pointerMoveEvent);
          m.redraw();
        };
        const clearEventListeners = () => {
          window.removeEventListener("pointermove", moveHandler);
          window.removeEventListener("pointerup", clearEventListeners);
          m.redraw();
        };
        window.addEventListener("pointermove", moveHandler);
        window.addEventListener("pointerup", clearEventListeners);
      };

      const dismissModal = () => {
        uploadedBlobUrl = undefined;
        const inputEl = (vnode as any).dom.querySelector("input.set-avatar") as HTMLInputElement;
        inputEl.value = "";
        imageLoaded = false;
      };

      const generateJpg = (size: number) => {
        return new Promise<void>((resolve) => {
          if (!uploadedBlobUrl) {
            return resolve();
          }
          const canvas = document.createElement("canvas");
          canvas.width = size;
          canvas.height = size;
          const ctx = canvas.getContext("2d");
          const img = new Image();
          img.addEventListener("load", () => {
            const resizeRatio = imgNaturalWidth / imgResizedWidth;
            const sx = minX * resizeRatio;
            const sy = minY * resizeRatio;
            const sWidth = (maxX - minX) * resizeRatio;
            const sHeight = (maxY - minY) * resizeRatio;
            ctx?.drawImage(img, sx, sy, sWidth, sHeight, 0, 0, size, size);
            canvas.toBlob(
              async (blob) => {
                const responseData = await apiRequest("generateAvatarPutUrl", {});
                if (!responseData.success) {
                  return resolve();
                }
                const url = responseData.url;

                const awsResponse = await m.request({
                  method: "PUT",
                  url,
                  serialize: (x) => x, // Don't try to JSON.stringify the body.
                  body: blob,
                });
                return resolve();
              },
              "image/jpeg",
              0.8
            );
          });
          img.src = uploadedBlobUrl;
        });
      };

      const updateAvatar = async () => {
        await generateJpg(400);
        await accountState.refreshLoggedInUser();

        dismissModal();
      };

      if (uploadedBlobUrl && imageLoaded) {
        let cX = (minX + maxX) / 2;
        let cY = (minY + maxY) / 2;
        let r = (maxX - minX) / 2;
        mPopup = m(".modal-container", [
          m(".modal-background-overlay", { onclick: dismissModal }),
          m(".avatar-modal", [
            m(".modal-x", { onclick: dismissModal }, m(Icon20, { icon: "x" })),
            m(".avatar-modal-inner", [
              // Image
              m("img.avatar-modal-image", { src: uploadedBlobUrl, width: imgResizedWidth }),

              // Spotlight
              m("svg.avatar-spotlight", [
                m("path", {
                  d: `M 0 0 L ${imgResizedWidth} 0 L ${imgResizedWidth} ${imgResizedHeight} L 0 ${imgResizedHeight} Z M ${
                    cX - r
                  }, ${cY} a ${r}, ${r} 0 1, 0 ${r * 2}, 0 a ${r}, ${r} 0 1, 0 ${-(r * 2)}, 0`,
                }),
              ]),
              // move spotlight
              m(".avatar-move-spotlight-box", {
                style: {
                  left: minX + "px",
                  top: minY + "px",
                  width: maxX - minX + "px",
                  height: maxY - minY + "px",
                },
                onpointerdown: (pointerDownEvent: PointerEvent) => {
                  const startX = pointerDownEvent.clientX;
                  const startY = pointerDownEvent.clientY;
                  const startMinX = minX;
                  const startMinY = minY;
                  const startMaxX = maxX;
                  const startMaxY = maxY;
                  startHandleDrag((pointerMoveEvent) => {
                    const currentX = pointerMoveEvent.clientX;
                    const currentY = pointerMoveEvent.clientY;
                    const dx = currentX - startX;
                    const dy = currentY - startY;
                    const adjustmentForX = clamp(dx, -startMinX, imgResizedWidth - startMaxX);
                    const adjustmentForY = clamp(dy, -startMinY, imgResizedHeight - startMaxY);
                    minX = startMinX + adjustmentForX;
                    minY = startMinY + adjustmentForY;
                    maxX = startMaxX + adjustmentForX;
                    maxY = startMaxY + adjustmentForY;
                  });
                },
              }),
              // Resize Handles
              m(".avatar-modal-handle", {
                style: { left: minX + "px", top: minY + "px", cursor: "nwse-resize" },
                onpointerdown: (pointerDownEvent: PointerEvent) => {
                  const startX = pointerDownEvent.clientX;
                  const startY = pointerDownEvent.clientY;
                  const startMinX = minX;
                  const startMinY = minY;
                  startHandleDrag((pointerMoveEvent) => {
                    const currentX = pointerMoveEvent.clientX;
                    const currentY = pointerMoveEvent.clientY;
                    const dx = currentX - startX;
                    const dy = currentY - startY;
                    const adjustment = clamp(
                      dy,
                      -Math.min(startMinX, startMinY),
                      maxX - startMinX - minSize
                    );
                    minX = startMinX + adjustment;
                    minY = startMinY + adjustment;
                  });
                },
              }),
              m(".avatar-modal-handle", {
                style: { left: maxX + "px", top: minY + "px", cursor: "nesw-resize" },
                onpointerdown: (pointerDownEvent: PointerEvent) => {
                  const startX = pointerDownEvent.clientX;
                  const startY = pointerDownEvent.clientY;
                  const startMaxX = maxX;
                  const startMinY = minY;
                  startHandleDrag((pointerMoveEvent) => {
                    const currentX = pointerMoveEvent.clientX;
                    const currentY = pointerMoveEvent.clientY;
                    const dx = currentX - startX;
                    const dy = currentY - startY;
                    const adjustment = clamp(
                      dy,
                      -Math.min(imgResizedWidth - startMaxX, startMinY),
                      maxY - startMinY - minSize
                    );
                    maxX = startMaxX - adjustment;
                    minY = startMinY + adjustment;
                  });
                },
              }),
              m(".avatar-modal-handle", {
                style: { left: minX + "px", top: maxY + "px", cursor: "nesw-resize" },
                onpointerdown: (pointerDownEvent: PointerEvent) => {
                  const startX = pointerDownEvent.clientX;
                  const startY = pointerDownEvent.clientY;
                  const startMinX = minX;
                  const startMaxY = maxY;
                  startHandleDrag((pointerMoveEvent) => {
                    const currentX = pointerMoveEvent.clientX;
                    const currentY = pointerMoveEvent.clientY;
                    const dx = currentX - startX;
                    const dy = currentY - startY;
                    const adjustment = clamp(
                      dy,
                      startMinX - maxX + minSize,
                      Math.min(startMinX, imgResizedHeight - startMaxY)
                    );
                    minX = startMinX - adjustment;
                    maxY = startMaxY + adjustment;
                  });
                },
              }),
              m(".avatar-modal-handle", {
                style: { left: maxX + "px", top: maxY + "px", cursor: "nwse-resize" },
                onpointerdown: (pointerDownEvent: PointerEvent) => {
                  const startX = pointerDownEvent.clientX;
                  const startY = pointerDownEvent.clientY;
                  const startMaxX = maxX;
                  const startMaxY = maxY;
                  startHandleDrag((pointerMoveEvent) => {
                    const currentX = pointerMoveEvent.clientX;
                    const currentY = pointerMoveEvent.clientY;
                    const dx = currentX - startX;
                    const dy = currentY - startY;
                    const adjustment = clamp(
                      dy,
                      -(startMaxX - minX) + minSize,
                      Math.min(imgResizedWidth - startMaxX, imgResizedHeight - startMaxY)
                    );
                    maxX = startMaxX + adjustment;
                    maxY = startMaxY + adjustment;
                  });
                },
              }),
            ]),
            m(".avatar-modal-button", [
              m("button", { onclick: updateAvatar }, "Update Profile Picture"),
            ]),
          ]),
        ]);
      }

      return m(".settings-form", [
        m("label.avatar-choose-image-button[role=button]", { for: "avatar" }, "Choose Image"),
        m("input.set-avatar", {
          type: "file",
          id: "avatar",
          accept: "image/png, image/jpeg",
          onchange: chooseImage,
        }),
        mPopup,
      ]);
    },
  };
};
