/**
 * This file manages modals. You can open a modal which grays out the rest of the
 * screen and shows the modal centered on the screen. If the user clicks the
 * grayed out background, this "dismisses" the modal.
 *
 * All state is kept in `modalState`.
 *
 * To open a modal, use `modalState.open(...)`.
 */

import m from "mithril";
import { Icon20 } from "./icon";

/******************************************************************************************
State
*******************************************************************************************/

export class Modal {
  view: () => m.Children;

  onDismiss?: () => void;

  constructor(view: () => m.Children, onDismiss?: () => void) {
    this.view = view;
    this.onDismiss = onDismiss;
  }
}

const onKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopPropagation();
    modalState.dismiss();
  }
};

interface ModalOptions {
  modalView: () => m.Children;
  onDismiss?: () => void;
}
class ModalState {
  modal: Modal | undefined;

  open({ modalView, onDismiss }: ModalOptions) {
    this.modal = new Modal(modalView, onDismiss);
    document.addEventListener("keydown", onKeyDown);
  }

  /**
   * Use this when the user "cancels" the modal. It will trigger the `onDismiss`
   * callback. This is what happens when the user clicks the background overlay
   * to dismiss the modal.
   */
  dismiss() {
    const { modal } = this;
    if (!modal) return;
    this.close();
    modal.onDismiss?.();
  }

  /**
   * Use this to close the modal without triggering the `onDismiss` callback.
   */
  close() {
    this.modal = undefined;
    document.removeEventListener("keydown", onKeyDown);
  }
}

export const modalState = new ModalState();

/******************************************************************************************
Container Mithril component
*******************************************************************************************/

/**
 * This mithril component renders the active modal, if one exists. It should be
 * included at the top level.
 */
export const ModalContainer: m.Component<{}> = {
  view() {
    const { modal } = modalState;
    if (!modal) return;
    return m(".modal-container", [
      m(".modal-background-overlay", { onclick: () => modalState.dismiss() }),
      modal.view(),
    ]);
  },
};

/******************************************************************************************
Utility Mithril components
*******************************************************************************************/

export const ModalX: m.Component<{}> = {
  view() {
    return m(".modal-x", { onclick: () => modalState.dismiss() }, m(Icon20, { icon: "x" }));
  },
};
