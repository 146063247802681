import m from "mithril";

import { logEvent } from "./log-event";
import { modalState, ModalX } from "./modal";
import { CuttlePro, DefaultUpgradeButtonSet, Feature, ProFeaturesChecklist } from "./upgrade";

const featureTaglines: Record<Feature, m.Children> = {
  projects: ["Create unlimited projects with ", m(CuttlePro)],
  images: ["Upload photos and images with ", m(CuttlePro)],
  fonts: ["Upload your own fonts with ", m(CuttlePro)],
  folders: ["Organize projects in folders with ", m(CuttlePro)],
  beta: ["Access pre-release (beta) features with ", m(CuttlePro)],
  downloads: ["Download unlimited designs with ", m(CuttlePro)],
  templates: ["Access premium templates with ", m(CuttlePro)],
  "pro-fonts": ["Access premium fonts with ", m(CuttlePro)],
};

interface UpgradeModalAttrs {
  feature?: Feature;
  openInNewWindow?: boolean;
}
const UpgradeModal: m.Component<UpgradeModalAttrs> = {
  view({ attrs }) {
    const { feature = "templates", openInNewWindow } = attrs;
    const tagline = featureTaglines[feature];
    return m(".modal-box.upgrade-modal", [
      m(ModalX),
      m(".upgrade-modal-top", [m("h1.tagline", tagline)]),
      m(".upgrade-modal-left", [
        m(ProFeaturesChecklist),
        m(".upgrade-modal-learn-more", [
          m(
            m.route.Link,
            {
              href: "/pricing",
              target: openInNewWindow ? "CuttlePricingWindow" : "_top",
              onclick: () => {
                logEvent("upgrade modal: learn more", { feature });
              },
            },
            "Learn more about Cuttle Pro"
          ),
        ]),
      ]),
      m(".upgrade-modal-right", m(DefaultUpgradeButtonSet, { feature, openInNewWindow })),
    ]);
  },
};

export const openUpgradeModal = (feature?: Feature, openInNewWindow?: boolean) => {
  logEvent("upgrade modal", { feature });
  modalState.open({
    modalView: () => {
      return m(UpgradeModal, {
        feature,
        openInNewWindow,
      });
    },
  });
};
