import { apiRequestSuccess } from "./api-request";
import { parseProjectUrl } from "./util";

export const logEvent = (eventName: string, metadata?: object) => {
  const { pathname } = window.location;
  const projectUrl = parseProjectUrl(pathname);
  const data = { eventName, projectId: projectUrl?.projectId, metadata };

  // Don't log events if running locally (development)
  if (window.location.host.includes("localhost.cuttle.xyz")) {
    console.log("[local logEvent]", data);
    return;
  }

  apiRequestSuccess("logEvent", data, { skipRedraw: true });
};
