import m from "mithril";
import { updateHead } from "./util";
import { SiteLayout } from "./shared/site-layout/site-layout";

export const ScheduledMaintenancePage: m.Component<{}> = {
  view() {
    updateHead({
      title: "Cuttle - Scheduled Maintenance",
    });

    return m(SiteLayout, { mainClass: "center-wrapper" }, [
      m(
        "p",
        "🚧 Cuttle is undergoing scheduled maintenance. We should be back in about 10 minutes. 🚧"
      ),
    ]);
  },
};
