import m from "mithril";
import { dashboardTagState } from "./tags";
import { HorizontalCarousel } from "./horizontal-carousel";

const tagStripOrdering = [
  // Most popular categories
  "customizable-text",
  "laser-cut-boxes",
  "cake-toppers",

  // In season
  "christmas", // Jul - Dec

  // More categories
  "jewelry-and-wearables",
  "sports",
  "keychains",
  "toys-and-puzzles",
  "ornaments",
  "tools-and-testers",
  "paper-and-cardstock",

  // Out of season
  "valentines-day", // Dec 25 - Feb 14
  "easter", // Feb 14 - Apr 1 (ish)
  "graduation", // Apr 1 - May 31
];

export const TagStrip: m.Component<{}> = {
  view() {
    return m(".tag-strip", [
      m(HorizontalCarousel, [
        tagStripOrdering.map((tagSlug) => {
          return m(TagCard, { tagSlug });
        }),
      ]),
    ]);
  },
};

interface TagCardAttrs {
  tagSlug: string;
}

export const TagCard: m.Component<TagCardAttrs> = {
  view({ attrs: { tagSlug } }) {
    const tagsAndProjects = dashboardTagState.getTagsAndProjects();
    if (tagsAndProjects === "loading") {
      return null;
    }

    const tag = tagsAndProjects.tags.find((tag) => tag.slug === tagSlug);
    if (tag === undefined) {
      return null;
    }

    const tagName = tag.tagName;

    return m(m.route.Link, { class: "tag-card", href: `/templates/${tagSlug}` }, [
      m("img", { src: `/images/tags-for-templates/${tagSlug}.jpg`, alt: "" }),
      m(".tag-card-name", tagName),
    ]);
  },
};
