import m from "mithril";

const rightArrowSVG = `
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.5 4.5L13 10L7.5 15.5" stroke="#333333" stroke-width="2"/>
</svg>
`;

export const HorizontalCarousel: m.ClosureComponent<{}> = () => {
  let resizeObserver: ResizeObserver;
  let scrollLeft = 0;
  let widthOfContainer = 1000;
  let widthOfContent = 2000;

  const onCreate = (scrollingEl: Element) => {
    scrollingEl.addEventListener("scroll", () => {
      onUpdate(scrollingEl);
      m.redraw();
    });
    resizeObserver = new ResizeObserver((entries) => {
      onUpdate(scrollingEl);
      m.redraw();
    });
    resizeObserver.observe(scrollingEl);
  };
  const onRemove = () => {
    resizeObserver.disconnect();
  };
  const onUpdate = (scrollingEl: Element) => {
    scrollLeft = scrollingEl.scrollLeft;
    widthOfContainer = scrollingEl.clientWidth;
    widthOfContent = scrollingEl.scrollWidth;
  };

  const goLeft = (e: Event) => {
    const buttonEl = e.target as HTMLElement;
    doScroll(buttonEl, false);
  };
  const goRight = (e: Event) => {
    const buttonEl = e.target as HTMLElement;
    doScroll(buttonEl, true);
  };
  const doScroll = (buttonEl: HTMLElement, right: boolean) => {
    const containerEl = buttonEl.closest(".horizontal-carousel")!;
    const scrollingEl = containerEl.querySelector(".horizontal-carousel-items")!;
    const itemEls = Array.from(scrollingEl.children) as HTMLElement[];

    // Get the padding of horizontal carousel items
    const scrollingElStyle = window.getComputedStyle(scrollingEl);
    const paddingLeft = parseFloat(scrollingElStyle.paddingLeft);
    const paddingRight = parseFloat(scrollingElStyle.paddingRight);

    // Find first element offscreen
    let newScrollLeft = 0;

    if (right) {
      const rightEdge = scrollLeft + widthOfContainer;
      for (let itemEl of itemEls) {
        const right = itemEl.offsetLeft + itemEl.offsetWidth;
        if (right > rightEdge) {
          newScrollLeft = itemEl.offsetLeft - paddingLeft;
          break;
        }
      }
    } else {
      const leftEdge = scrollLeft;
      itemEls.reverse();
      for (let itemEl of itemEls) {
        const left = itemEl.offsetLeft;
        if (left < leftEdge) {
          newScrollLeft = itemEl.offsetLeft + itemEl.offsetWidth - widthOfContainer + paddingRight;
          break;
        }
      }
    }

    scrollingEl.scroll({
      top: 0,
      left: newScrollLeft,
      behavior: "smooth",
    });
  };

  return {
    view(vnode) {
      const isSmallCarousel = widthOfContainer < 420;

      const shouldShowLeftScrollButton = scrollLeft > 0 && !isSmallCarousel;
      let mLeft;
      if (shouldShowLeftScrollButton) {
        mLeft = m(
          "button.horizontal-carousel-left-scroll-button",
          { onclick: goLeft, "aria-hidden": "true" },
          m(".horizontal-carousel-scroll-button-circle", m.trust(rightArrowSVG))
        );
      }

      const shouldShowRightScrollButton =
        scrollLeft < widthOfContent - widthOfContainer && !isSmallCarousel;
      let mRight;
      if (shouldShowRightScrollButton) {
        mRight = m(
          "button.horizontal-carousel-right-scroll-button",
          { onclick: goRight, "aria-hidden": "true" },
          m(".horizontal-carousel-scroll-button-circle", m.trust(rightArrowSVG))
        );
      }

      return m(".horizontal-carousel", [
        mLeft,
        m(".horizontal-carousel-items", [vnode.children]),
        mRight,
      ]);
    },
    oncreate(vnode) {
      const scrollingEl = vnode.dom.querySelector(".horizontal-carousel-items")!;
      onCreate(scrollingEl);
      onUpdate(scrollingEl);
    },
    onupdate(vnode) {
      const scrollingEl = vnode.dom.querySelector(".horizontal-carousel-items")!;
      onUpdate(scrollingEl);
    },
    onremove(vnode) {
      onRemove();
    },
  };
};
