import m from "mithril";
import { updateHead } from "./util";
import { apiRequestSuccess } from "./shared/api-request";
import { ProjectCardData } from "./shared/api-types";
import { Card } from "./shared/card";
import { SiteLayout } from "./shared/site-layout/site-layout";
import { projectCardState } from "./shared/projects-card-state";

// ============================================================================
// State
// ============================================================================

class ExploreState {
  private exploreProjects: ProjectCardData[] | "loading" | undefined;

  private async fetchProjects() {
    this.exploreProjects = "loading";
    const { projectCards } = await apiRequestSuccess("getProjectCardsForExploreLatest", {});
    projectCardState.rememberProjectCards(projectCards);
    this.exploreProjects = projectCards;
    m.redraw();
  }

  getProjects() {
    if (!this.exploreProjects) {
      this.fetchProjects();
      return "loading";
    }
    return this.exploreProjects!;
  }
}
export const exploreState = new ExploreState();

// ============================================================================
// View
// ============================================================================

interface ExploreLatestPageAttrs {
  showAll: boolean;
}

export const ExploreLatestPage: m.Component<ExploreLatestPageAttrs> = {
  view({ attrs: { showAll } }) {
    updateHead({
      title: "Cuttle - Explore",
    });

    const projects = exploreState.getProjects();
    let mCards;

    if (projects === "loading") {
      mCards = null;
    } else if (showAll) {
      const allCommunityProjects = projects.filter((project) => {
        return project.ownerUsername !== "cuttle";
      });
      mCards = allCommunityProjects!.slice(0, 200).map((project) => {
        return m(Card, { project, key: project.projectId });
      });
    } else {
      const communityProjectsWithCoverPhotos = projects.filter((project) => {
        return project.ownerUsername !== "cuttle" && project.coverPhoto;
      });
      mCards = communityProjectsWithCoverPhotos.map((project) => {
        return m(Card, { project, key: project.projectId });
      });
    }

    let mExploreFilter;

    if (showAll) {
      mExploreFilter = m(".explore-filter", [
        m(m.route.Link, { href: "/explore" }, "Projects with Photos"),
        m("span", "Latest"),
      ]);
    } else {
      mExploreFilter = m(".explore-filter", [
        m("span", "Projects with Photos"),
        m(m.route.Link, { href: "/explore/all" }, "Latest"),
      ]);
    }

    return m(SiteLayout, [
      m(".main-inner", [
        m("h2", "Latest Shared Projects from the Community"),
        mExploreFilter,
        m(".card-grid", [mCards]),
      ]),
    ]);
  },
};
