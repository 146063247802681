import m from "mithril";

import { accountState } from "./account";
import { rewardfulReferral } from "./analy-tics";
import { apiRequestSuccess } from "./api-request";
import { PaymentPeriod } from "./api-types";
import { apiOrigin } from "./config";
import { ProFeatureTag } from "./feature-check";
import { Icon20 } from "./icon";
import { logEvent } from "./log-event";
import { openUpgradeModal } from "./upgrade-modal";
import { classNames, goToDashboard } from "./util";

export const CuttlePro: m.Component = {
  view() {
    return m("span.cuttle-pro", [m("strong", "Cuttle "), m(ProFeatureTag)]);
  },
};

export const FreeFeaturesChecklist: m.Component = {
  view() {
    return m(".pricing-features", [
      m(PricingFeature, "Create 5 free projects"),
      m(PricingFeature, "10 free downloads per month"),
      m(PricingFeature, "Personal use only"),
    ]);
  },
};

export const ProFeaturesChecklist: m.Component = {
  view() {
    return m(".pricing-features", [
      m(PricingFeature, "Unlimited projects"),
      m(PricingFeature, "Unlimited downloads"),
      m(PricingFeature, "Access premium templates"),
      m(PricingFeature, "Upload JPG and PNG images"),
      m(PricingFeature, "Upload your own fonts"),
      m(PricingFeature, "Organize projects in folders"),
      m(PricingFeature, "Early access to beta features"),
      m(PricingFeature, "Personal or commercial use"),
    ]);
  },
};

export type Feature =
  | "projects"
  | "images"
  | "fonts"
  | "folders"
  | "beta"
  | "downloads"
  | "templates"
  | "pro-fonts";

export interface UpgradeInfo {
  paymentPeriod: PaymentPeriod;
}

export interface UpgradeAttrs {
  info: UpgradeInfo;
  feature?: Feature;
  openInNewWindow?: boolean;
}

export const PricingFeature: m.Component = {
  view({ children }) {
    return m(".pricing-feature", [
      m(Icon20, { icon: "check_bullet" }),
      m(".pricing-feature-description", children),
    ]);
  },
};

interface DefaultUpgradeButtonSetAttrs {
  feature?: Feature;
  openInNewWindow?: boolean;
}
export const DefaultUpgradeButtonSet: m.ClosureComponent<DefaultUpgradeButtonSetAttrs> = () => {
  const info: UpgradeInfo = { paymentPeriod: "monthly" };
  return {
    view({ attrs: { feature, openInNewWindow } }) {
      const plan = accountState.loggedInUser?.subscription.plan;
      return [
        plan !== "Pro" &&
          plan !== "K-12 Education" &&
          m(UpgradePlanSelector, { info, feature, openInNewWindow }),
        m(ProPricingButton, { info, feature, openInNewWindow }),
      ];
    },
  };
};

export const UpgradePlanSelector: m.Component<UpgradeAttrs> = {
  view({ attrs: { info } }) {
    const referral = rewardfulReferral();
    return m(".upgrade-plan-selector", [
      m(".upgrade-plans", [
        m(
          ".upgrade-plan",
          {
            className: classNames({ selected: info.paymentPeriod === "monthly" }),
            onclick: () => (info.paymentPeriod = "monthly"),
          },
          [
            m(PlanCheckbox, { checked: info.paymentPeriod === "monthly" }),
            referral
              ? [
                  m("h3", "Monthly Membership"),
                  m("h3.right", [m("s", "$19"), " $17.10"]),
                  m("span.left", ""),
                  m("span.right", "Renews at $19 / month"),
                ]
              : [
                  m("h3", "Monthly Membership"),
                  m("h3.right", "$19"),
                  m("span.left", ""),
                  m("span.right", "per month"),
                ],
          ]
        ),
        m(
          ".upgrade-plan",
          {
            className: classNames({ selected: info.paymentPeriod === "yearly" }),
            onclick: () => (info.paymentPeriod = "yearly"),
          },
          [
            m(PlanCheckbox, { checked: info.paymentPeriod === "yearly" }),
            referral
              ? [
                  m("h3", "Yearly Membership"),
                  m("h3.right", [m("s", "$180"), " 162"]),
                  m("span.left", "Save 20%!"),
                  m("span.right", "Renews at $180 / year"),
                ]
              : [
                  m("h3", "Yearly Membership"),
                  m("h3.right", "$180"),
                  m("span", "$15 per month. Save 20%!"),
                  m("span.right", "per year"),
                ],
          ]
        ),
        referral ? m(".discount", "Discount applied: 10% off your first purchase") : null,
      ]),
    ]);
  },
};

export const PlanCheckbox: m.Component<{ checked: boolean }> = {
  view({ attrs: { checked } }) {
    return m(".upgrade-plan-checkbox", [
      m(Icon20, { icon: checked ? "plan_checked" : "plan_unchecked" }),
    ]);
  },
};

export const FreePricingButton: m.Component = {
  view() {
    const loggedInUser = accountState.loggedInUser;
    if (!loggedInUser) {
      // Get Started Free, sign up modal
      return m(
        "button.secondary",
        { onclick: () => accountState.openAccountModal("signup", goToDashboard) },
        "Get Started Free"
      );
    }
  },
};

export const ProPricingButton: m.Component<UpgradeAttrs> = {
  view({
    attrs: {
      info: { paymentPeriod },
      feature,
      openInNewWindow,
    },
  }) {
    const loggedInUser = accountState.loggedInUser;
    if (!loggedInUser) {
      return m(
        "button.pricing-upgrade-button",
        {
          onclick: async () => {
            const success = await accountState.openAccountModalPromise("signup");
            if (success) {
              if (accountState.loggedInUser?.subscription.plan === "Free") {
                logEvent("checkout", { plan: "pro", paymentPeriod });
                // We made an async call so we can't obey `openInNewWindow`.
                if (openInNewWindow) {
                  openUpgradeModal(feature, openInNewWindow);
                } else {
                  window.location.href = checkoutUrl(paymentPeriod);
                }
              }
            }
          },
        },
        "Upgrade to Pro"
      );
    }

    const subscription = loggedInUser.subscription;
    if (subscription.plan === "Free") {
      return m(
        m.route.Link,
        {
          role: "button",
          href: checkoutUrl(paymentPeriod),
          target: openInNewWindow ? "CuttlePricingWindow" : "_top",
          className: "pricing-upgrade-button",
          onclick: () => {
            logEvent("checkout", { plan: "pro", paymentPeriod });
          },
        },
        "Upgrade to Pro"
      );
    } else {
      return m(ManageAccountButton);
    }
  },
};

export const ManageAccountButton: m.Component = {
  view() {
    const plan = accountState?.loggedInUser?.subscription.plan;
    if (plan === "Pro") {
      return m(
        "button.secondary",
        { onclick: redirectToCustomerPortalSessionUrl },
        "Manage Your Subscription"
      );
    } else if (plan === "K-12 Education") {
      return m(".pricing-current-plan", [
        m("div", [
          m("span", "You’re on the K-12 Education plan. "),
          m("br"),
          m("span", "Contact "),
          m("a", { href: "mailto: education@cuttle.xyz" }, "education@cuttle.xyz"),
          m("span", " to make changes to your plan."),
        ]),
      ]);
    }
  },
};

const checkoutUrl = (paymentPeriod: PaymentPeriod) => {
  const referral = rewardfulReferral();
  let url = `${apiOrigin}/checkout/pro/${paymentPeriod}`;
  if (referral) {
    url += `?referral=${referral}`;
  }
  return url;
};

const redirectToCustomerPortalSessionUrl = async () => {
  const { customerPortalSessionUrl } = await apiRequestSuccess("createCustomerPortalSession", {});
  window.location.href = customerPortalSessionUrl;
};
