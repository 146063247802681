import m from "mithril";

import { accountState } from "./account";
import { Feature } from "./upgrade";
import { openUpgradeModal } from "./upgrade-modal";

/** Opens model with a message about the feature, if the user does not have
 * access to the feature. */
export const checkUserHasProFeature = (feature: Feature) => {
  const hasFeature = accountState.featureFlags.hasProFeatures;
  if (!hasFeature) {
    openUpgradeModal(feature, true);
  }
  return hasFeature;
};

/**
 * ProFeatureButton displays the "pro" icon when a user is not pro. If a non-pro
 * user clicks the button, and upgrade modal is shown. Otherwise, the `onClick`
 * handler is called.
 */
interface ProFeatureButtonAttrs {
  onClick: (event: PointerEvent) => void;
  feature: Feature;
  className?: string;
  disabled?: boolean;
  enableProCheck?: boolean;
}
export const ProFeatureButton: m.Component<ProFeatureButtonAttrs> = {
  view({ attrs: { onClick, feature, className, disabled, enableProCheck }, children }) {
    enableProCheck = enableProCheck === undefined || enableProCheck;

    let onclick = onClick;
    if (enableProCheck) {
      onclick = (event: PointerEvent) => {
        if (checkUserHasProFeature(feature)) {
          onClick(event);
        }
      };
    }

    return m("button", { className, disabled, onclick }, [
      children,
      m(ProFeatureTagUnlessPro, { showTag: enableProCheck }),
    ]);
  },
};

export const ProFeatureTagUnlessPro: m.Component<{ showTag: boolean }> = {
  view({ attrs: { showTag } }) {
    if (!showTag) return;
    if (accountState.featureFlags.hasProFeatures) return;
    return m(ProFeatureTag);
  },
};

export const ProFeatureTag: m.Component = {
  view() {
    return m(".feature-tag.pro-feature-tag", "PRO");
  },
};
