import m from "mithril";

interface AvatarAttrs {
  avatarUrl: string;
  username: string;
  size: number;
}

export const AvatarImage: m.ClosureComponent<AvatarAttrs> = () => {
  let avatarExists = true;
  return {
    view({ attrs: { avatarUrl, username, size } }) {
      const avatarImageLetter = username.charAt(0);
      let mAvatar;
      if (avatarExists) {
        mAvatar = m("img.avatar", {
          src: avatarUrl,
          width: size,
          height: size,
          onerror: () => {
            avatarExists = false;
          },
          alt: username,
        });
      } else {
        mAvatar = m(
          ".avatar-letter-container",
          {
            style: {
              width: size + "px",
              height: size + "px",
              fontSize: size * 0.5 + "px",
              lineHeight: size + "px",
              border: size * 0.05 + "px solid",
            },
            "aria-label": username,
          },
          [m(".avatar-letter", { "aria-hidden": "true" }, avatarImageLetter.toUpperCase())]
        );
      }
      return mAvatar;
    },
  };
};
