import { apiRequest, apiRequestSuccess } from "./shared/api-request";
import type { ProjectCardData } from "./shared/api-types";
import { generateRandomId } from "./util";
import { accountState } from "./shared/account";

export interface Folder {
  folderId: string;
  name: string;
  ordering: number;
}

class DashboardState {
  /**
   * This is used to keep track of if your projects have loaded when you first
   * log in. When you first log in, your projects is empty [] -- which is a
   * valid state and your projects will eventually load -- but we don't want to
   * display a flash of "no projects" UI so we keep track of this.
   */
  hasLoadedProjects = false;

  projects: ProjectCardData[] = [];
  folders: Folder[] = [];

  // Dragging state
  folderBeingDragged: Folder | undefined;
  dragX: number = 0;
  dragY: number = 0;
  projectBeingDragged: ProjectCardData | undefined;

  folderById(folderId: string) {
    return this.folders.find((folder) => folder.folderId === folderId);
  }

  projectsInFolder(folderId: string) {
    return this.projects.filter((project) => project.folderId === folderId);
  }

  firstFolderId() {
    if (this.folders.length < 1) return undefined;
    return this.folders[0].folderId;
  }

  async refresh() {
    if (!accountState.loggedInUser) {
      this.projects = [];
      this.folders = [];
      this.hasLoadedProjects = false;
      return;
    }
    const { projects, folders } = await apiRequestSuccess("getDashboard", {});
    this.projects = projects;
    this.folders = folders;
    this.hasLoadedProjects = true;
  }

  async deleteProject(projectId: string) {
    // optimistic update
    this.projects = this.projects.filter((project) => project.projectId !== projectId);
    await apiRequestSuccess("deleteProject", { projectId });
    // Note: we refresh the user (which in turn refreshes dashboardState)
    // because we also want to refresh the number of projects (for the free
    // account limit)
    await accountState.refreshLoggedInUser();
  }

  async duplicateProject(projectId: string) {
    // Note: no optimistic update here, we should probably show a progress indicator.
    await apiRequest("duplicateProject", { projectId });
    // Note: we refresh the user (which in turn refreshes dashboardState)
    // because we also want to refresh the number of projects (for the free
    // account limit)
    await accountState.refreshLoggedInUser();
  }

  async renameProject(projectId: string, newName: string) {
    // optimistic update
    const project = this.projects.find((project) => project.projectId === projectId);
    if (project) project.name = newName;
    await apiRequestSuccess("renameProject", {
      projectId: projectId,
      name: newName,
    });
    await this.refresh();
  }

  async createFolder() {
    const name = "New Folder";
    const folderId = generateRandomId(24);
    // optimisic update
    this.folders.push({
      folderId,
      name,
      ordering: this.folders.length,
    });
    await apiRequestSuccess("createFolder", { folderId, name });
    await this.refresh();
  }

  async renameFolder(folderId: string, newName: string) {
    // optimistic update
    const folder = this.folderById(folderId);
    if (folder) folder.name = newName;
    await apiRequestSuccess("renameFolder", { folderId, newName });
    await this.refresh();
  }

  async deleteFolder(folderId: string) {
    // optimistic update. Note: we're not fixing the orderings here, but the server will do that.
    this.folders = this.folders.filter((folder) => folder.folderId !== folderId);
    await apiRequestSuccess("deleteFolder", { folderId });
    await this.refresh();
  }

  async moveProjectToFolder(projectId: string, folderId: string) {
    // optimistic update
    const project = this.projects.find((project) => project.projectId === projectId);
    if (project) project.folderId = folderId;
    await apiRequestSuccess("moveProjectToFolder", { projectId, folderId });
    await this.refresh();
  }

  async reorderFolder(folderId: string, insertionIndex: number) {
    // optimistic update. Note: we're not fixing the orderings here, but the server will do that.
    const currentIndex = this.folders.findIndex((folder) => folder.folderId === folderId);
    const folderToMove = this.folders.splice(currentIndex, 1)[0];
    if (insertionIndex > currentIndex) {
      this.folders.splice(insertionIndex - 1, 0, folderToMove);
    } else {
      this.folders.splice(insertionIndex, 0, folderToMove);
    }
    await apiRequestSuccess("reorderFolder", { folderId, insertionIndex });
    await this.refresh();
  }
}

export const dashboardState = new DashboardState();
accountState.onRefresh(() => {
  dashboardState.refresh();
});
(window as any).dashboardState = dashboardState;
