import m from "mithril";

import { EditableText } from "./shared/editable-text";
import { Icon20 } from "./shared/icon";
import { createPopupMenu } from "./shared/popup";
import { navState } from "./shared/site-layout/nav-state";
import { classNames } from "./shared/util";
import { Folder, dashboardState } from "./dashboard-state";
import { isPointerEventPrimaryMouseButton, simpleStartDrag } from "./util";
import { ProFeatureButton } from "./shared/feature-check";
import { DashboardSelectedPage } from "./dashboard";
import { dashboardTagState } from "./tags";
import { SidebarLink, SidebarSection } from "./sidebar";
import { accountState } from "./shared/account";

interface DashboardSidebarAttrs {
  selectedPage: DashboardSelectedPage;
}

export const DashboardSidebar: m.Component<DashboardSidebarAttrs> = {
  view({ attrs: { selectedPage } }) {
    return m(".sidebar.dashboard-sidebar", [
      m(".dashboard-sidebar-inner", [
        m(SidebarHome, { selectedPage }),
        m(SidebarMyProjects, { selectedPage }),
        m(SidebarTemplates, { selectedPage }),
      ]),
    ]);
  },
};

const SidebarHome: m.Component<DashboardSidebarAttrs> = {
  view({ attrs: { selectedPage } }) {
    if (!accountState.loggedInUser) {
      return null;
    }
    const onclick = () => {
      m.route.set("/dashboard", undefined, { title: "Cuttle - Dashboard Home" });
    };

    const isSelected = selectedPage.page === "home";

    const className = classNames({
      "is-highlighted": isSelected,
    });
    return m(".sidebar-section", [
      //
      m(".sidebar-item.is-top-level", { className, onclick }, "Home"),
    ]);
  },
};

export const SidebarMyProjects: m.Component<DashboardSidebarAttrs> = {
  view({ attrs: { selectedPage } }) {
    if (!accountState.loggedInUser) {
      return null;
    }
    const folders = dashboardState.folders;

    const addNewFolder = () => {
      dashboardState.createFolder();
    };

    let selectedFolderId: string | undefined = undefined;
    if (selectedPage.page === "folder") {
      selectedFolderId = selectedPage.folderId;
    }

    return m(SidebarSection, { title: "My Projects" }, [
      folders.map((folder, insertionIndex) => [
        m(FolderReorderHitTarget, { insertionIndex }),
        m(FolderLink, { folder, isSelected: folder.folderId === selectedFolderId }),
      ]),
      m(FolderReorderHitTarget, { insertionIndex: folders.length }),

      m(
        ProFeatureButton,
        {
          onClick: addNewFolder,
          feature: "folders",
          className: "sidebar-new-folder-button",
        },
        "+ New Folder"
      ),
      // m(".sidebar-item", ["+ New Folder"]),
    ]);
  },
};

export const SidebarTemplates: m.Component<DashboardSidebarAttrs> = {
  view({ attrs: { selectedPage } }) {
    const tagsAndProjects = dashboardTagState.getTagsAndProjects();
    if (tagsAndProjects === "loading") {
      return null; // Todo: add spinnner?
    }

    const { tags, projects } = tagsAndProjects;

    return m(SidebarSection, { title: "Templates" }, [
      m(SidebarLink, { url: "/templates", title: "All Templates" }),
      tags
        .sort((a, b) => a.tagName.localeCompare(b.tagName))
        .map((tag) => m(SidebarLink, { url: `/templates/${tag.slug}`, title: tag.tagName })),
    ]);
  },
};

interface FolderReorderHitTargetAttrs {
  insertionIndex: number;
}

const FolderReorderHitTarget: m.Component<FolderReorderHitTargetAttrs> = {
  view({ attrs: { insertionIndex } }) {
    return m(".folder-reorder-hit-target", {
      onpointerup: () => {
        // reorder folder if folder is being dragged
        if (dashboardState.folderBeingDragged) {
          const folderId = dashboardState.folderBeingDragged.folderId;
          dashboardState.reorderFolder(folderId, insertionIndex);
        }
      },
      onpointerenter: (event: PointerEvent) => {
        (event.target as HTMLElement).classList.add("hover");
      },
      onpointerleave: (event: PointerEvent) => {
        (event.target as HTMLElement).classList.remove("hover");
      },
    });
  },
};

interface FolderLinkAttrs {
  folder: Folder;
  isSelected: boolean;
}

const FolderLink: m.ClosureComponent<FolderLinkAttrs> = (vnode) => {
  let isPopupOpen = false;
  let isRenameOpen = false;
  let shouldOpenRename = false;
  let dragInProgress = false;
  let isHovered = false;

  return {
    view({ attrs: { folder, isSelected } }) {
      if (!dashboardState) {
        // Still loading dashboard state
        return null;
      }

      const className = classNames({
        "is-highlighted": isSelected,
        "is-hovered": isHovered,
      });

      const deleteFolderFromMenu = async () => {
        if (dashboardState.projectsInFolder(folder.folderId).length > 0) {
          window.alert("This folder contains projects and cannot be deleted.");
        } else {
          dashboardState.deleteFolder(folder.folderId);
          // If currently on this folder route, go back to dashboard home.
          if (isSelected) {
            m.route.set("/dashboard", undefined, { replace: true });
          }
        }
      };

      const renameFolderEditableText = async (newName: string) => {
        newName = newName.trim();
        if (newName === "") {
          return;
        }
        dashboardState.renameFolder(folder.folderId, newName);
      };

      const openRename = async () => {
        shouldOpenRename = true;
      };

      const openMenu = (event: Event) => {
        event.stopPropagation();
        createPopupMenu({
          menuItems: [
            {
              label: "Rename",
              action: openRename,
            },
            {
              label: "Delete",
              action: deleteFolderFromMenu,
              enabled: () => dashboardState.folders.length > 1,
            },
          ],
          spawnFrom: (vnode as any).dom.querySelector(".sidebar-dotdotdot") as HTMLElement,
          placement: "bottom-end",
          onclose: () => (isPopupOpen = false),
        });
        isPopupOpen = true;
      };

      let autoSelect = shouldOpenRename;
      shouldOpenRename = false;

      const folderPointerDown = (event: PointerEvent) => {
        if (!isPointerEventPrimaryMouseButton(event)) return;

        simpleStartDrag(event, {
          onConsummate: (moveEvent) => {
            dashboardState.dragX = moveEvent.clientX;
            dashboardState.dragY = moveEvent.clientY;
            dashboardState.folderBeingDragged = folder;
            dragInProgress = true;
            document.body.classList.add("dragging-folder");
          },
          onMove: (moveEvent) => {
            dashboardState.dragX = moveEvent.clientX;
            dashboardState.dragY = moveEvent.clientY;
          },
          onUp: () => {
            dashboardState.folderBeingDragged = undefined;
            // delay setting dragInProgress to false until after the click event
            window.requestAnimationFrame(() => {
              dragInProgress = false;
            });
            document.body.classList.remove("dragging-folder");
          },
        });
      };

      const folderClick = () => {
        if (dragInProgress) {
          return;
        }
        m.route.set(
          "/dashboard/folder/:folder",
          { folder: folder.folderId },
          { title: "Cuttle - Folder" }
        );
        navState.isHamburgerOpen = false;
      };

      return m(
        ".sidebar-item",
        {
          className,
          onpointerdown: folderPointerDown,
          onclick: folderClick,
          onpointerup: () => {
            // moving projectId into folder if a project is being dragged
            if (dashboardState.projectBeingDragged) {
              const projectId = dashboardState.projectBeingDragged.projectId;
              const newFolderId = folder.folderId;
              dashboardState.moveProjectToFolder(projectId, newFolderId);
            }
          },
          onpointerenter: () => {
            isHovered = true;
          },
          onpointerleave: () => {
            isHovered = false;
          },
        },
        [
          m(EditableText, {
            value: folder.name,
            onopen: () => (isRenameOpen = true),
            onclose: () => (isRenameOpen = false),
            onchange: renameFolderEditableText,
            autoSelect,
          }),
          m(".sidebar-dotdotdot", { onclick: openMenu }, m(Icon20, { icon: "dotdotdot" })),
        ]
      );
    },
  };
};
