import m from "mithril";

import { SiteLayout } from "./shared/site-layout/site-layout";
import {
  CuttlePro,
  DefaultUpgradeButtonSet,
  FreeFeaturesChecklist,
  FreePricingButton,
  ProFeaturesChecklist,
} from "./shared/upgrade";
import { fetchText, markdownToHtml, updateHead } from "./util";

export const PricingPage: m.Component<{}> = {
  view() {
    updateHead({
      title: `Cuttle - Pricing`,
    });

    return m(SiteLayout, [
      m(".pricing-splash", [
        m(".pricing-container", [
          m(".pricing-left", [
            m("h1", "Free for casual use"),
            m(FreeFeaturesChecklist),
            m(FreePricingButton),
          ]),
          m(".pricing-right", [
            m("h1", ["Join ", m(CuttlePro), " for full access"]),
            m(ProFeaturesChecklist),
            m(DefaultUpgradeButtonSet),
          ]),
        ]),
      ]),
      m(".main-inner", [
        m(".callout", [
          "Using Cuttle in the classroom? See our ",
          m(m.route.Link, { href: "/pricing/k-12" }, "K-12 Education pricing"),
          ".",
        ]),
      ]),

      m(WallOfLoveScrolling),

      m(".main-inner", [
        m("h1", "Questions & Answers"),

        m(".pricing-faq", [m(markdownContent, { markdownUrl: "/content/pricing-faq.md" })]),
      ]),
    ]);
  },
};

export const markdownContent: m.Component<{ markdownUrl: string }> = {
  view({ attrs: { markdownUrl } }) {
    let mContents;

    const md = fetchText(markdownUrl);
    if (md === undefined) {
      // Loading
    } else {
      const html = markdownToHtml(md, markdownUrl);
      mContents = m.trust(html);
    }
    return mContents;
  },
};

export const WallOfLoveScrolling: m.Component<{}> = {
  view() {
    return m(".wall-of-love-scrolling", [
      m("h1", "Loved by Makers"),
      m.trust(
        `<iframe height="800px" id='testimonialto-cuttle-tag-all-light-animated' src="https://embed-v2.testimonial.to/w/cuttle?animated=on&theme=light&shadowColor=f5f5f5&speed=1&hideDate=on&tag=all&disableHoverPause" frameborder="0" scrolling="no" width="100%"></iframe>`
      ),
    ]);
  },
};
