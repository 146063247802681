import m from "mithril";

import { accountState } from "./shared/account";
import { SiteLayout } from "./shared/site-layout/site-layout";
import { PricingFeature } from "./shared/upgrade";
import { fetchText, markdownToHtml, updateHead } from "./util";
import { WallOfLoveScrolling } from "./pricing";

const ContactUsButton: m.Component = {
  view() {
    return m(
      "a",
      {
        target: "_blank",
        role: "button",
        href: "https://docs.google.com/forms/d/e/1FAIpQLSeWyScLykKVmnnrudzZSUdqzVx6LSjvp_gfFqP1l7hwDgWWqg/viewform?usp=sf_link",
      },
      "Contact Us"
    );
  },
};

export const K12PricingPage: m.Component<{}> = {
  view() {
    updateHead({
      title: `Cuttle - K-12 Education Pricing`,
    });

    let mK12Message: m.Children;
    if (accountState.loggedInUser?.subscription.plan === "K-12 Education") {
      mK12Message = m(".pricing-current-plan-k12", [
        m("span", "You’re on the K-12 Education plan. "),
        m("br"),
        m("span", "Contact "),
        m("a", { href: "mailto: education@cuttle.xyz" }, "education@cuttle.xyz"),
        m("span", " to make changes to your plan."),
      ]);
    }

    return m(SiteLayout, [
      m(".main-inner", [
        m("h1.title", "K-12 Education Pricing"),
        m(".pricing-position", [
          m(".pricing-container", [
            m(".pricing-left", [
              m("h1.k12-plan", "Classroom"),
              m("h3.k12-price", "$350 / year"),
              m(".pricing-features", [
                m(
                  PricingFeature,
                  "All accounts under your school’s domain will be upgraded to the Pro feature set"
                ),
                m(PricingFeature, "Up to 30 active accounts per week"),
              ]),
              m(ContactUsButton),
            ]),
            m(".pricing-right", [
              m("h1.k12-plan", "School"),
              m("h3.k12-price", "$750 / year"),
              m(".pricing-features", [
                m(
                  PricingFeature,
                  "All accounts under your school’s domain will be upgraded to the Pro feature set"
                ),
                m(PricingFeature, "Unlimited accounts"),
              ]),
              m(ContactUsButton),
            ]),
          ]),
          mK12Message,
        ]),

        m(".callout.pricing-plan-message", [
          "Using Cuttle as an individual? See our ",
          m(m.route.Link, { href: "/pricing" }, "individual pricing"),
          ".",
        ]),
      ]),

      m(WallOfLoveScrolling),

      m(".main-inner", [
        m("h1.pricing-header", "Questions & Answers"),

        m(".pricing-faq", [m(markdownContent, { markdownUrl: "/content/pricing-faq-k12.md" })]),
      ]),
    ]);
  },
};

export const markdownContent: m.Component<{ markdownUrl: string }> = {
  view({ attrs: { markdownUrl } }) {
    let mContents;

    const md = fetchText(markdownUrl);
    if (md === undefined) {
      // Loading
    } else {
      const html = markdownToHtml(md, markdownUrl);
      mContents = m.trust(html);
    }
    return mContents;
  },
};
