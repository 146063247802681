import m from "mithril";
import { ModalContainer } from "../modal";
import { PopupContainer } from "../popup";
import { Footer } from "./footer";
import { Nav } from "./nav";

/**
 * Includes PopupContainer and ModalContainer along with its children. Use this
 * to wrap the entire site.
 */
export const SiteContainer: m.Component<{}> = {
  view(vnode) {
    return [vnode.children, m(PopupContainer), m(ModalContainer)];
  },
};

interface SiteLayoutAttrs {
  // Useful for announcements or important messages
  displayAboveNav?: m.Children;

  // Apply additional class to the top-level .site-layout div
  className?: string;

  // Useful if you need e.g. main to be display: flex
  mainClass?: string;

  // Use if you need extra stuff (e.g. links) to appear in the hamburger menu.
  hamburgerExtra?: () => m.Children;
}

/**
 * Creates a .site-layout containing Nav, Main (its children), and Footer.
 */
export const SiteLayout: m.Component<SiteLayoutAttrs> = {
  view(vnode) {
    const { displayAboveNav, className, mainClass, hamburgerExtra } = vnode.attrs;
    return m(SiteContainer, [
      //
      m(".site-layout", { className }, [
        //
        displayAboveNav,
        m(Nav, { hamburgerExtra }),
        m("main", { className: mainClass }, vnode.children),
        m(Footer),
      ]),
    ]);
  },
};
