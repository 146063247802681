import m from "mithril";

import * as allIcons from "./icons";
import { Tooltipped } from "./popup";

export type IconName = keyof typeof allIcons;

interface IconAttrs {
  icon: IconName;
}
export const Icon14: m.Component<IconAttrs> = {
  view({ attrs: { icon } }) {
    const svgString = allIcons[icon];
    return m(".icon14", { "aria-hidden": "true" }, m.trust(svgString));
  },
};
export const Icon16: m.Component<IconAttrs> = {
  view({ attrs: { icon } }) {
    const svgString = allIcons[icon];
    return m(".icon16", { "aria-hidden": "true" }, m.trust(svgString));
  },
};
export const Icon20: m.Component<IconAttrs> = {
  view({ attrs: { icon } }) {
    const svgString = allIcons[icon];
    return m(".icon20", { "aria-hidden": "true" }, m.trust(svgString));
  },
};
export const IconSVG: m.Component<IconAttrs> = {
  view({ attrs: { icon } }) {
    const svgString = allIcons[icon];
    return m.trust(svgString);
  },
};

interface IconButtonAttrs {
  icon: IconName;
  label?: string;
  onclick?: (event: PointerEvent) => void;
  onpointerdown?: (event: PointerEvent) => void;
  className?: string;
}
export const IconButton: m.Component<IconButtonAttrs> = {
  view({ attrs: { icon, label, onclick, onpointerdown, className } }) {
    const mButton = m(
      ".icon-button",
      { onclick, onpointerdown, className, role: "button", "aria-label": label },
      m(Icon20, { icon })
    );
    if (label) {
      return m(Tooltipped, { message: () => label }, mButton);
    }
    return mButton;
  },
};
