import m from "mithril";
import { accountState } from "./account";
import { apiRequest } from "./api-request";
import { logEvent } from "./log-event";
import { modalState } from "./modal";
import { metadataForNewAccount } from "./analy-tics";

let google: undefined | any;

// This function will be called once the user successfully signs in using
// Google. It's a bit hacky to make it a global let like this, but we have to
// register the handleCredentialResponse function when we initialize the Google
// sign in library. This let allows us to swap out what happens when the user
// logs in.
let googleSignInOnSuccess: undefined | (() => void);

export const handleCredentialResponse = async (response: any) => {
  // Close the account modal and open the "Google sign in loading" modal.
  modalState.open({
    modalView: () => {
      return m(".modal-box", [
        m(".google-sign-in-loading", [
          m("p", "Signing in with Google..."),
          m(".loading-indicator"),
        ]),
      ]);
    },
  });
  m.redraw();

  const googleCredential = response.credential;
  await apiRequest("signInWithGoogleCredential", {
    googleCredential,
    metadata: metadataForNewAccount(),
  });
  await accountState.refreshLoggedInUser();
  logEvent("sign in with google");
  modalState.close();
  googleSignInOnSuccess?.();
};

// initialize Google
window.addEventListener("load", () => {
  google = (window as any).google;
  if (!google) return;

  google.accounts.id.initialize({
    client_id: "951058506648-nicpvptr0vpj6ajqqe8qmfh3cvc1nivc.apps.googleusercontent.com",
    callback: handleCredentialResponse,
  });
});

interface GoogleSignInButtonAttrs {
  onSuccess?: () => void;
}
export const GoogleSignInButton: m.Component<GoogleSignInButtonAttrs> = {
  view({ attrs: { onSuccess } }) {
    // A bit hacky. This swaps out the global onSuccess callback with the latest
    // one that was passed to us.
    googleSignInOnSuccess = onSuccess;

    // This empty div will be populated with the google button in the oncreate.
    return m(".google-sign-in-button");
  },
  oncreate(vnode) {
    const el = vnode.dom;
    const rect = el.getBoundingClientRect();
    const width = rect.width;
    const renderButtonWhenReady = () => {
      if (google) {
        google.accounts.id.renderButton(
          el,
          { theme: "filled_blue", text: "continue_with", width, size: "large" } // customization attributes
        );
      } else {
        setTimeout(renderButtonWhenReady, 100);
      }
    };
    renderButtonWhenReady();
  },
};
