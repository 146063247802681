import m from "mithril";

export const TestimonialsTemplates: m.Component = {
  view() {
    return m(".testimonials-section", [
      m(Testimonial, {
        image: "/images/testimonials/CapnMike.jpg",
        quote: "Cuttle is an indispensable tool in my crafting tool bag.",
        name: "Cap’n Mike",
      }),
      m(Testimonial, {
        image: "/images/testimonials/Heather-Simmonsen.jpg",
        quote:
          "I love how easy it is to create designs that adjust to different material thicknesses, kerf, and dimensions. Cuttle’s quickly become my favorite design tool.",
        name: "Heather Simmonsen (Chelsea Custom Creations)",
      }),
    ]);
  },
};

export const TestimonialsEditor: m.Component = {
  view() {
    return m(".testimonials-section", [
      m(Testimonial, {
        image: "/images/testimonials/Amelia-Wattenberger.jpg",
        quote:
          "I’ve always wanted a tool like Cuttle — purpose-built for generating parametric art.",
        name: "Amelia Wattenberger",
      }),
      m(Testimonial, {
        image: "/images/testimonials/Christopher-Masto.jpg",
        quote: "This is the software I didn’t know I always wanted for 2D design.",
        name: "Christopher Masto",
      }),
    ]);
  },
};

export const TestimonialsCommunity: m.Component = {
  view() {
    return m(".testimonials-section", [
      m(Testimonial, {
        image: "/images/testimonials/Chris-Haske.jpg",
        quote: "Cuttle brings together my passions of engineering and artmaking.",
        name: "Chris Haske",
      }),
      m(Testimonial, {
        image: "/images/testimonials/Josh-Estelle.jpg",
        quote: "Cuttle is really, really great.",
        name: "Josh Estelle",
      }),
    ]);
  },
};

interface TestimonialAttrs {
  image: string;
  quote: string;
  name: string;
}
export const Testimonial: m.Component<TestimonialAttrs> = {
  view({ attrs: { image, quote, name } }) {
    return m(".individual-testimonial", [
      m("img", { src: image, alt: "" }),
      m(".testimonial-text", [
        //
        m("p.quote", quote),
        // m("p", `— ${name}`),
        m("p", name),
      ]),
    ]);
  },
};
