import m from "mithril";
import { LandingPageDemo } from "./landing-demo";

interface LandingPageHeroAttrs {
  cta: m.Children;
}
export const LandingPageHero: m.Component<LandingPageHeroAttrs> = {
  view({ attrs: { cta } }) {
    return m(".hero2", [
      m(".hero2-headline", [
        "Generate",
        m("br"),
        m(".hero2-headline-gradient", [
          //
          "Personalized",
          m("br"),
          "SVG Cut Files",
        ]),
        m("br"),
        "in seconds.",
        cta,
      ]),
      m(LandingPageDemo, {
        demoUrl: "/@cuttle/Paw-Ornament-Landing-Page-Demo-ti565ZMSqjV3",
        projectUrl: "/@cuttle/Paw-Ornament-irWGANS1uLkF",
        paramName: "name",
        paramValues: ["Ruby", "Bear", "Roxie", "JoJo", "Luna", "Magic"],
      }),
    ]);
  },
};
