import m from "mithril";
import { apiRequest } from "./shared/api-request";
import { updateHead } from "./util";
import { goToDashboard } from "./shared/util";
import { accountState } from "./shared/account";
import { SiteLayout } from "./shared/site-layout/site-layout";

const queryStringObject = m.parseQueryString(window.location.search);

export const ResetPasswordPage: m.ClosureComponent<{}> = () => {
  const token = "" + queryStringObject.token;
  let passwordProvided = true;
  return {
    oninit() {
      const checkResetTokenValid = async () => {
        const responseData = await apiRequest("isResetTokenValid", { token });

        if (responseData.success) {
          m.route.set("/reset-password", {}, { replace: true });
        } else {
          m.route.set("/invalid-reset-token", {}, { replace: true });
        }
      };
      checkResetTokenValid();
    },

    view(vnode) {
      updateHead({
        title: "Cuttle - Reset Password",
      });

      const resetPassword = async (event: Event) => {
        event.preventDefault();

        passwordProvided = true;

        const el: HTMLElement = (vnode as any).dom;

        const password = (el.querySelector(".reset-password") as HTMLInputElement).value;

        if (!password) {
          passwordProvided = false;
          return;
        }

        const responseData = await apiRequest("resetPassword", { token, password });

        if (responseData.success) {
          await accountState.refreshLoggedInUser();
          m.route.set("/dashboard");
        }
      };

      let mError;

      if (!passwordProvided) {
        mError = m("p.account-error", "Please provide your password");
      }

      return m(SiteLayout, { mainClass: "center-wrapper" }, [
        m("form.form-wrapper", { onsubmit: resetPassword }, [
          m(".form-title", "Reset Password"),
          m(".label-input", [
            m("label.label", "Password"),
            m("input.reset-password", { type: "password" }),
          ]),
          m("button", "Reset Password"),
          mError,
        ]),
      ]);
    },
  };
};

export const InvalidResetTokenPage: m.Component<{}> = {
  view() {
    updateHead({
      title: "Cuttle - Reset Password",
    });

    return m(SiteLayout, { mainClass: "center-wrapper" }, [
      m("p", "The link you used is either expired or invalid."),
      m(
        "p",
        m(
          "span[role=link]",
          { onclick: () => accountState.openAccountModal("forgotPassword", goToDashboard) },
          "Forgot Password?"
        )
      ),
    ]);
  },
};
