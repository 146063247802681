import m from "mithril";
import { apiRequest } from "./shared/api-request";
import { accountState } from "./shared/account";

const logout = async () => {
  const responseData = await apiRequest("logout", {});

  if (responseData.success) {
    await accountState.refreshLoggedInUser();
    m.route.set("/", {}, { replace: true });
  }
};

export const Logout: m.Component<{}> = {
  oninit() {
    logout();
  },
  view() {
    return null;
  },
};
