import m from "mithril";

import { apiRequestSuccess } from "./shared/api-request";
import { ProjectCardData } from "./shared/api-types";
import { AvatarImage } from "./shared/avatar-image";
import { Card } from "./shared/card";
import { SiteLayout } from "./shared/site-layout/site-layout";
import { updateHead } from "./util";

interface UserProfilePageAttrs {
  username: string;
}

export const UserProfilePage: m.ClosureComponent<UserProfilePageAttrs> = () => {
  let data:
    | undefined
    | "loading"
    | {
        username: string;
        projects: ProjectCardData[];
        displayName: string;
        avatar: string;
      };

  const loadData = async (username: string) => {
    if (data === "loading") return; // In the process of loading
    if (data && data.username === username) return; // Already loaded

    data = "loading";

    const projectsResult = await apiRequestSuccess("getProjectCardsForProfile", { username });
    const profileInfoResult = await apiRequestSuccess("getProfileInfo", { username });

    data = {
      username,
      projects: projectsResult.projectCards,
      displayName: profileInfoResult.displayName,
      avatar: profileInfoResult.avatar,
    };
  };

  return {
    oninit({ attrs: { username } }) {
      loadData(username);
    },

    onupdate({ attrs: { username } }) {
      loadData(username);
    },

    view({ attrs: { username } }) {
      updateHead({
        title: `Cuttle - @${username}'s Profile Page`,
      });

      if (!data || data === "loading") {
        return null;
      }

      let mCards = data.projects.map((project) => {
        return m(Card, { project, key: project.projectId });
      });

      let mSidebar;

      mSidebar = m(".profile-sidebar", [
        m(AvatarImage, {
          avatarUrl: data.avatar,
          username: username,
          size: 248,
        }),
        m(".profile-display-name", data.displayName),

        m(".profile-username", `@${username}`),
      ]);

      return m(SiteLayout, [
        //
        m(".main-inner.profile-inner", [
          //
          mSidebar,
          m(".card-grid", [mCards]),
        ]),
      ]);
    },
  };
};
