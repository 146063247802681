import m from "mithril";
import { updateHead } from "./util";
import { Sidebar } from "./sidebar";
import { SiteLayout } from "./shared/site-layout/site-layout";
import { canonicalOrigin, genOrigin } from "./shared/config";

interface PlaylistData {
  id: string;
  title: string;
  description: string;
  videos: VideoData[];
}
interface VideoData {
  id: string;
  title: string;
  description: string;
  publishedAt: string;
  thumbnailUrl: string;
  duration: string;
}

const orderPlaylists = (playlists: PlaylistData[]) => {
  const playlistsOrdering = [
    "PLEVSQTxqVer9slE7LQlJuCvIYKlJXG2oF", // Getting Started with Cuttle
    "PLEVSQTxqVer83Gc3g4kRzI57tdH6IFrZv", // Full Project Tutorials
    "PLEVSQTxqVer8Fwf-vVnzDaBd08r3zUQ-H", // Facebook Live Sessions
    "PLEVSQTxqVer-3SyzNaUPQ2srdKZUfRfP9", // Cuttle Q&A
    "PLEVSQTxqVer-M1yKrgqg2uC_4on9TaYft", // Live Workshops
    "PLEVSQTxqVer8D7VzTTp3Wotw9aN5nNZ7d", // Parametric Design in Cuttle
    "PLEVSQTxqVer_Tr87gVhXIe5t7ysUkOwa-", // Modifiers in Cuttle
    "PLEVSQTxqVer85twhEwu2CqFAR-3QbqeDX", // Cuttle Tips & Tricks
    "PLEVSQTxqVer9nyFmF6A5Jk4H-6FV60zHf", // Shorts
    "PLEVSQTxqVer_s1DEEtYAvJrJqa136WSvy", // Explorations
  ];

  return playlists.sort((a, b) => {
    return playlistsOrdering.indexOf(a.id) - playlistsOrdering.indexOf(b.id);
  });
};

class YouTubeState {
  private playlists: PlaylistData[] | "loading" | undefined;

  private async fetch() {
    this.playlists = "loading";
    const response = await window.fetch(genOrigin + "/youtube.json");
    const data = await response.json();
    this.playlists = orderPlaylists(data.playlists);
    m.redraw();
  }

  get() {
    if (!this.playlists) {
      this.fetch();
      return "loading";
    }
    return this.playlists;
  }
}
const youTubeState = new YouTubeState();

//

interface TutorialVideoAttrs {
  href: string;
  image: string;
  title: string;
  duration: string;
}

export const TutorialVideo: m.Component<TutorialVideoAttrs> = {
  view({ attrs: { href, image, title, duration }, children }) {
    return m("a.tutorial-tile", { href: href, target: "_blank" }, [
      m(
        ".container-16-by-9",
        [m("img.tutorial-image", { src: image })],
        m(".tutorial-video-duration", duration)
      ),
      m("p", title),
    ]);
  },
};

interface PlaylistSectionAttrs {
  playlist: PlaylistData;
}

export const PlaylistSection: m.Component<PlaylistSectionAttrs> = {
  view({ attrs: { playlist } }) {
    console.log(playlist);
    const mTutorialVideos = playlist.videos.map((video) => {
      if (video.title === "Deleted video") {
        // Sometimes youtube returns a deleted video.
        return null;
      }
      return m(TutorialVideo, {
        href: `https://www.youtube.com/watch?v=${video.id}`,
        image: video.thumbnailUrl,
        title: video.title,
        duration: video.duration,
      });
    });
    return m(".tutorials-section", [
      m("h1", playlist.title),
      m("p", playlist.description),
      m(".tutorial-tiles", [mTutorialVideos]),
    ]);
  },
};

export const Tutorials: m.Component<{}> = {
  view() {
    updateHead({
      title: "Cuttle - Tutorials",
      image: canonicalOrigin + "/images/tutorials/tea-light-lamp.jpg",
    });

    const youTubeData = youTubeState.get();

    if (youTubeData === "loading") {
      return null;
    }

    const mPlaylistSections = youTubeData.map((playlist) => {
      return m(PlaylistSection, { playlist });
    });

    return m(SiteLayout, { hamburgerExtra: () => m(Sidebar) }, [
      m(".white-background-when-small", [
        m(".main-inner", [
          m(".with-sidebar", [
            m(Sidebar),
            m("div", [m("h1.title", "Video Tutorials"), mPlaylistSections]),
          ]),
        ]),
      ]),
    ]);
  },
};
