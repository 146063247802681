import m from "mithril";
import { domForVnode, isObject, isString } from "../shared/util";

type Phase = "write" | "complete" | "erase" | "empty";

class DemoTyper {
  names: string[];

  nameIndex = 0;
  name = "";

  phase: Phase = "write";
  phaseIntervals: Record<Phase, number> = {
    write: 160,
    complete: 2000,
    erase: 120,
    empty: 200,
  };

  onChange?: (newValue: string) => void;

  _timeout?: number;

  constructor(names: string[]) {
    this.names = names;
  }

  start() {
    this.step();
  }
  pause() {
    window.clearTimeout(this._timeout);
    this._timeout = undefined;
  }
  stop() {
    this.pause();
    // this.set("");
    this.phase = "write";
  }
  isPlaying() {
    return this._timeout !== undefined;
  }

  next() {
    this._timeout = window.setTimeout(() => this.step(), this.phaseIntervals[this.phase]);
    if (this.phase === "write") {
      const targetName = this.names[this.nameIndex];
      this.set(targetName.slice(0, this.name.length + 1));
      if (this.name.length === targetName.length) {
        this.phase = "complete";
      }
    } else if (this.phase === "complete") {
      this.phase = "erase";
    } else if (this.phase === "erase") {
      this.set(this.name.slice(0, -1));
      if (this.name.length === 0) {
        this.nameIndex = (this.nameIndex + 1) % this.names.length;
        this.phase = "empty";
      }
    } else if (this.phase === "empty") {
      this.phase = "write";
    }
  }
  step() {
    this.pause();
    this.next();
  }

  set(name: string) {
    this.name = name;
    this.onChange?.(name);
  }
}

const selectAllOnPointerUp = (inputEl: HTMLInputElement) => {
  const removeEventListener = () => {
    document.removeEventListener("pointerup", selectAll);
  };
  const selectAll = () => {
    inputEl.select();
    removeEventListener();
  };
  document.addEventListener("pointerup", selectAll);
  setTimeout(removeEventListener, 500);
};

interface LandingPageDemoAttrs {
  demoUrl: string;
  projectUrl: string;
  paramName: string;
  paramValues: string[];
}
export const LandingPageDemo: m.ClosureComponent<LandingPageDemoAttrs> = (initialVnode) => {
  const updateValue = (newValue?: string) => {
    const rootEl = domForVnode(initialVnode);
    const iframeEl = rootEl.querySelector(".landing-page-demo-embed") as HTMLIFrameElement;
    const inputEl = rootEl.querySelector(".landing-page-demo-input") as HTMLInputElement;

    if (isString(newValue)) {
      inputEl.value = newValue;
    } else {
      newValue = inputEl.value;
    }

    const message = {
      action: "setProjectParameter",
      name: initialVnode.attrs.paramName,
      expressionCode: JSON.stringify(newValue),
    };
    iframeEl?.contentWindow?.postMessage(message);
  };

  const typer = new DemoTyper(initialVnode.attrs.paramValues);
  typer.onChange = updateValue;
  window.addEventListener("message", (event) => {
    if (isObject(event.data) && event.data.status === "ready") {
      typer.start();
    }
  });

  return {
    view(vnode) {
      const { demoUrl, projectUrl, paramName, paramValues } = vnode.attrs;
      return m(".landing-page-demo", [
        m("iframe.landing-page-demo-embed", {
          title: "Demo showing personalized design based on text input.",
          src: `${demoUrl}?embed=true`,
        }),
        m(".landing-page-demo-form", [
          m(".landing-page-demo-label", ["Try it!", m("span.landing-page-demo-arrow", " →")]),
          m("input.landing-page-demo-input", {
            "aria-label": `Name for the personalized design.`,
            oninput: updateValue,
            onfocus: (event: Event) => {
              if (typer.isPlaying()) {
                typer.stop();
                const inputEl = event.target as HTMLInputElement;
                selectAllOnPointerUp(inputEl);
              }
            },
          }),
          m("a.landing-page-demo-button[role=button]", { href: projectUrl }, [
            "Customize",
            m("span.landing-page-demo-arrow", " →"),
          ]),
        ]),
      ]);
    },
  };
};
