import marked from "marked";
import m from "mithril";
import { accountState } from "./shared/account";
import { canonicalOrigin } from "./shared/config";
import { goToDashboard } from "./shared/util";

export const generateRandomId = (length: number) => {
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let id = "";
  for (let i = 0; i < length; i++) {
    id += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return id;
};

export const checkEmailValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const enforceLogin = () => {
  m.route.set("/", {}, { replace: true });
  accountState.openAccountModal("login", goToDashboard);
  return null;
};

export const adminIsLoggedIn = () => {
  return accountState.loggedInUser?.isAdmin;
};

interface HeadOptions {
  title?: string;
  description?: string;
  image?: string;
}
export const updateHead = (headOptions: HeadOptions) => {
  const { title, description, image } = headOptions;

  // Title
  if (title) {
    document.title = title;
    const titleEl1 = document.querySelector("title");
    if (titleEl1) titleEl1.innerText = title;
    const titleEl2 = document.querySelector("meta[property='og:title']");
    if (titleEl2) titleEl2.setAttribute("content", title);
  }

  // Description
  const descriptionEl1 = document.querySelector("meta[name='description']");
  const descriptionEl2 = document.querySelector("meta[property='og:description']");
  if (description) {
    if (descriptionEl1) descriptionEl1.setAttribute("content", description);
    if (descriptionEl2) descriptionEl2.setAttribute("content", description);
  } else {
    if (descriptionEl1) descriptionEl1.removeAttribute("content");
    if (descriptionEl2) descriptionEl2.removeAttribute("content");
  }

  // Image (e.g. for social media)
  if (image) {
    const imageEl1 = document.querySelector("meta[property='og:image']");
    if (imageEl1) imageEl1.setAttribute("content", image);
  }

  // Canonical URL (Facebook wants this)
  const canonicalUrl = canonicalOrigin + location.pathname;
  const urlEl1 = document.querySelector("meta[property='og:url']");
  if (urlEl1) urlEl1.setAttribute("content", canonicalUrl);
};

const loadingTexts: { [url: string]: boolean } = {};
const fetchedTexts: { [url: string]: string } = {};
export const fetchText = (url: string) => {
  // If we already have it, return it.
  if (fetchedTexts[url]) return fetchedTexts[url];

  // If it's loading, return undefined, meaning: still loading.
  if (loadingTexts[url]) return undefined;

  // Set up a request for it.
  loadingTexts[url] = true;
  m.request({
    url,
    extract: (xhr) => {
      return xhr.responseText;
    },
  })
    .then((response) => {
      if (typeof response === "string") {
        fetchedTexts[url] = response;
      } else {
        console.warn("Fetch failed", url, response);
      }
    })
    .finally(() => {
      loadingTexts[url] = false;
    });

  // Return undefined, meaning: still loading.
  return undefined;
};

const markdownOptions = {
  smartypants: true,
  gfm: true,
  baseUrl: "/content/learn/", // TODO: Might need to make this dynamic based on what page we're rendering.
};
export const markdownToHtml = (md: string, filename?: string) => {
  return marked(md, {
    smartypants: true,
    gfm: true,
    baseUrl: filename?.replace(/\/[^/]*$/, "/"),
  });
};

export const isPointerEventPrimaryMouseButton = (downEvent: PointerEvent) => {
  return downEvent.pointerType === "mouse" && downEvent.button === 0;
};

// Time thresholds are in milliseconds, distance thresholds are in pixels.
const consummationTimeThreshold = 200; // once the mouse is down at least this long the drag is consummated
const consummationDistanceThreshold = 4; // once the mouse moves at least this distance the drag is consummated

// This is a much stripped down version of `startDrag` from the editor codebase.
export const simpleStartDrag = (
  downEvent: PointerEvent,
  {
    onConsummate,
    onMove,
    onUp,
  }: {
    onConsummate?: (moveEvent: PointerEvent) => void;
    onMove?: (moveEvent: PointerEvent) => void;
    onUp?: (upEvent: PointerEvent) => void;
  }
) => {
  const dragStartX = downEvent.clientX;
  const dragStartY = downEvent.clientY;
  const dragStartTime = downEvent.timeStamp;

  let dragConsummated = false;

  const onPointerMove = (moveEvent: PointerEvent) => {
    if (moveEvent.pointerId !== downEvent.pointerId) return;

    if (dragConsummated) {
      onMove?.(moveEvent);
      m.redraw();
    } else {
      const deltaTime = moveEvent.timeStamp - dragStartTime;
      const deltaPosition =
        Math.abs(moveEvent.clientX - dragStartX) + Math.abs(moveEvent.clientY - dragStartY);
      if (
        deltaTime >= consummationTimeThreshold ||
        deltaPosition >= consummationDistanceThreshold
      ) {
        dragConsummated = true;
        onConsummate?.(moveEvent);
        m.redraw();
      }
    }
  };
  const onPointerUp = (upEvent: PointerEvent) => {
    if (upEvent.pointerId !== downEvent.pointerId) return;
    if (dragConsummated) {
      onUp?.(upEvent);
    }
    cleanup();
    m.redraw();
  };
  const cleanup = () => {
    window.removeEventListener("pointermove", onPointerMove);
    window.removeEventListener("pointerup", onPointerUp);
    window.document.body.removeAttribute("data-css-cursor");
  };
  window.addEventListener("pointermove", onPointerMove);
  window.addEventListener("pointerup", onPointerUp);
};
