import m from "mithril";

import { accountState } from "./shared/account";
import type { ProjectCardData } from "./shared/api-types";
import { EditableText } from "./shared/editable-text";
import { Icon20 } from "./shared/icon";
import { createPopupMenu, MenuItem } from "./shared/popup";
import { openUpgradeModal } from "./shared/upgrade-modal";
import {
  canonicalUrlForProject,
  classNames,
  imgixThumbnailAttrs,
  transparentPngUrl,
} from "./shared/util";
import { dashboardState } from "./dashboard-state";
import { isPointerEventPrimaryMouseButton, simpleStartDrag } from "./util";

export const Tile: m.ClosureComponent<{
  project: ProjectCardData;
}> = () => {
  let isPopupOpen = false;
  let isRenameOpen = false;
  let shouldOpenRename = false;
  let dragInProgress = false;
  return {
    view(vnode) {
      const { project } = vnode.attrs;

      let mPublishedStatus;
      if (project.shareStatus === "public") {
        mPublishedStatus = m(".tile-share-status-container", [
          m(".tile-public", project.shareStatus),
        ]);
      } else if (project.shareStatus === "unlisted") {
        mPublishedStatus = m(".tile-share-status-container", [
          m(".tile-unlisted", project.shareStatus),
        ]);
      } else {
        mPublishedStatus = m(".tile-share-status-container", [
          m(".tile-private", project.shareStatus),
        ]);
      }

      const deleteProject = () => {
        if (window.confirm("Are you sure you want to delete this project? 😨")) {
          dashboardState.deleteProject(project.projectId);
        }
      };

      const duplicateProject = () => {
        dashboardState.duplicateProject(project.projectId);
      };

      const projectUrl = canonicalUrlForProject(
        accountState.loggedInUser?.username ?? "",
        project.name,
        project.projectId
      );

      const renameProject = (newName: string) => {
        isRenameOpen = false;
        dashboardState.renameProject(project.projectId, newName);
      };

      const openRename = async () => {
        shouldOpenRename = true;
      };

      const folders = dashboardState.folders;

      const openMenu = () => {
        const menuItems: MenuItem[] = [
          {
            label: "Rename",
            action: openRename,
          },
          {
            label: "Delete",
            action: deleteProject,
          },
          {
            label: "Duplicate",
            action: () => {
              const loggedInUser = accountState.loggedInUser;
              const subscription = loggedInUser!.subscription;
              if (subscription.plan === "Free" && subscription.projectsRemaining === 0) {
                openUpgradeModal("projects");
              } else {
                duplicateProject();
              }
            },
          },
        ];

        if (accountState.featureFlags.hasProFeatures) {
          menuItems.push({
            label: "Move to Folder",
            submenu: () => {
              return folders.map((folder) => {
                return {
                  label: folder.name,
                  icon: () =>
                    folder.folderId === project.folderId ? m(Icon20, { icon: "check" }) : undefined,
                  action: () => {
                    const projectId = project.projectId;
                    const newFolderId = folder.folderId;
                    dashboardState.moveProjectToFolder(projectId, newFolderId);
                  },
                };
              });
            },
            enabled: () => dashboardState.folders.length > 1,
          });
        }

        createPopupMenu({
          menuItems,
          spawnFrom: (vnode as any).dom.querySelector(".tile-menu") as HTMLElement,
          placement: "bottom-end",
          onclose: () => (isPopupOpen = false),
        });
        isPopupOpen = true;
      };

      const className = classNames({
        "tile-menu-open": isPopupOpen,
        "tile-rename-open": isRenameOpen,
      });

      let autoSelect = shouldOpenRename;
      shouldOpenRename = false;

      const tilePointerDown = (event: PointerEvent) => {
        if (!isPointerEventPrimaryMouseButton(event)) return;

        if (!accountState.featureFlags.hasProFeatures) {
          return;
        }

        simpleStartDrag(event, {
          onConsummate: (moveEvent) => {
            dashboardState.dragX = moveEvent.clientX;
            dashboardState.dragY = moveEvent.clientY;
            dashboardState.projectBeingDragged = project;
            dragInProgress = true;
            document.body.classList.add("dragging-project");
          },
          onMove: (moveEvent) => {
            dashboardState.dragX = moveEvent.clientX;
            dashboardState.dragY = moveEvent.clientY;
          },
          onUp: () => {
            dashboardState.projectBeingDragged = undefined; // reset state
            // delay setting dragInProgress to false until after the click event
            window.requestAnimationFrame(() => {
              dragInProgress = false;
            });
            document.body.classList.remove("dragging-project");
          },
        });
      };

      const tileClick = (event: Event) => {
        // if the drag is in progress, don't follow the link (ignore click event)
        if (dragInProgress) {
          event.preventDefault();
        }
      };

      return m(".tile-container", { className }, [
        mPublishedStatus,

        m(".tile-menu-container", { onclick: openMenu }, [
          m(".tile-menu", m(Icon20, { icon: "dotdotdot" })),
        ]),
        m(
          "a",
          {
            href: projectUrl,
            target: "_blank",
            onpointerdown: tilePointerDown,
            draggable: false,
            onclick: tileClick,
            // Needed to prevent buggy behaviour in Firefox
            ondragstart: (event: Event) => event.preventDefault(),
          },
          [m(PreviewImage, { project })]
        ),
        m(".tile-info-container", [
          m(".tile-filename", [
            m(EditableText, {
              value: project.name,
              onopen: () => (isRenameOpen = true),
              onclose: () => (isRenameOpen = false),
              onchange: renameProject,
              autoSelect,
              singleClick: true,
            }),
          ]),
        ]),
      ]);
    },
  };
};

export const PreviewImage: m.Component<{ project: ProjectCardData }> = {
  view({ attrs: { project } }) {
    const alt = project.name;
    const handleMissingImage = (event: Event) => {
      const imgEl = event.target as HTMLImageElement;
      if (imgEl.src !== transparentPngUrl) {
        imgEl.removeAttribute("srcset");
        imgEl.src = transparentPngUrl;
      }
      console.log("handleMissingImage!");
    };
    if (project.coverPhoto) {
      const { src, srcset } = imgixThumbnailAttrs(project.coverPhoto);
      return m(".container-16-by-9", [m("img.tile", { alt, draggable: false, src, srcset })]);
    } else {
      return m(".container-16-by-9", [
        m("img.tile", {
          alt,
          draggable: false,
          src: project.previewImage,
          // Mithril bug? Need srcset here to be able to switch between
          // coverPhoto and previewImage. Expected: srcset would be removed from
          // the DOM if it's not here in the VDOM. Actual: srcset changes to
          // "null" which breaks the img.
          srcset: `${project.previewImage} 1x`,
          onerror: handleMissingImage,
        }),
      ]);
    }
  },
};
