import { apiRequestSuccess } from "./api-request";
import { ProjectCardData } from "./api-types";

class ProjectCardState {
  // Generic Projects By ID

  // We want to remember every ProjectCard that we've fetched.
  private projectCardsByProjectId: Record<string, ProjectCardData | "loading"> = {};
  rememberProjectCard(projectCard: ProjectCardData) {
    this.projectCardsByProjectId[projectCard.projectId] = projectCard;
  }
  rememberProjectCards(projectCards: ProjectCardData[]) {
    for (let projectCard of projectCards) {
      this.rememberProjectCard(projectCard);
    }
  }

  private projectsToFetch: Set<string> = new Set();
  private willFetchNextFrame: boolean = false;

  private fetchProjectNextFrame(projectId: string) {
    this.projectCardsByProjectId[projectId] = "loading";
    this.projectsToFetch.add(projectId);
    if (!this.willFetchNextFrame) {
      this.willFetchNextFrame = true;
      requestAnimationFrame(() => {
        this.fetchQueuedProjects();
        this.willFetchNextFrame = false;
      });
    }
  }

  private async fetchQueuedProjects() {
    const projectIds = Array.from(this.projectsToFetch);
    this.projectsToFetch.clear();
    const { projectCards } = await apiRequestSuccess("getProjectCardsByProjectIds", { projectIds });
    this.rememberProjectCards(projectCards);
  }

  getProject(projectId: string) {
    if (!this.projectCardsByProjectId[projectId]) {
      this.fetchProjectNextFrame(projectId);
    }
    return this.projectCardsByProjectId[projectId];
  }
}

export const projectCardState = new ProjectCardState();
