import m from "mithril";

import { Icon20 } from "./icon";
import { Accelerator } from "./keyboard";
import { classNames, domForVnode } from "./util";

// ----------------------------------------------------------------------------
// Generic popups and tooltips
// ----------------------------------------------------------------------------

type Placement =
  | "top"
  | "top-start"
  | "top-end"
  | "left"
  | "left-start"
  | "left-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "right"
  | "right-start"
  | "right-end";

type Overlay = boolean | "closeOnOutsidePointerDown";

export interface PopupOptions {
  // What to display in the popup.
  view: () => m.Children;

  // Optionally give the popup a className. (The popup will automatically be
  // given popup class.)
  className?: string;

  // We spawn the popup either relative to an HTMLElement or a position (e.g.
  // clientX and clientY of a right click).
  spawnFrom: HTMLElement | { x: number; y: number };

  // Placing the popup. These will "flip" if we're too close to the edge of the
  // screen. See https://popper.js.org/ for a demo of the 12 placement options.
  placement?: Placement;

  // Offset the positioning further. Defaults to 0.
  offset?: number;
  offsetH?: number;
  offsetV?: number;

  // Optionally get called back when the popup closes.
  onclose?: () => void;

  // Defaults true. This places a transparent overlay at z-index 1000. When you
  // click the overlay, the popup closes. So use `true` for real popups and
  // `false` for tooltips. There's also an in-between option,
  // "closeOnOutsidePointerDown". This doesn't use an overlay, so other
  // interface elements are still accessible. However it will automatically
  // close the popup if there's a pointerdown event outside of the popup.
  overlay?: Overlay;

  // Popups won't be placed above this position.
  yMin?: number;

  // Close the popup on Enter key press.
  closeOnEnter?: boolean;
}

export interface CreatedPopup {
  close: () => void;
  registerChildPopup: (childPopup: CreatedPopup) => void;
}

// State

interface PopupState {
  view: () => m.Children;
  className?: string;
  initialize: (popupEl: HTMLElement) => Position;
  hasBeenInitialized: boolean;
  onclose?: () => void;
  overlay?: Overlay;
  id: number;
  childPopup?: CreatedPopup;
}
let popups: PopupState[] = [];
let popupIdCounter = 0;

// Functionality

export const createPopup = (options: PopupOptions): CreatedPopup => {
  const spawnFrom = options.spawnFrom;
  const placement = options.placement ?? "bottom-start";
  const offset = options.offset ?? 0;
  const offsetH = options.offsetH ?? 0;
  const offsetV = options.offsetV ?? 0;
  const overlay = options.overlay ?? true;
  const yMin = options.yMin ?? 0;
  const closeOnEnter = options.closeOnEnter ?? false;

  let spawnRect: Rect;
  if (spawnFrom instanceof HTMLElement) {
    spawnRect = spawnFrom.getBoundingClientRect();
  } else {
    spawnRect = rectFromPoint(spawnFrom.x, spawnFrom.y);
  }

  let catchOutsidePointerDown: ((event: PointerEvent) => void) | undefined;

  const closeOnEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape" || (closeOnEnter && event.key === "Enter")) {
      event.stopPropagation();
      close();
      m.redraw();
    }
  };

  const initialize = (popupEl: HTMLElement) => {
    if (overlay === "closeOnOutsidePointerDown") {
      catchOutsidePointerDown = (event: PointerEvent) => {
        if (event.target instanceof Node && !isDescendantOf(event.target, popupEl)) {
          close();
          m.redraw();
        }
      };
      document.addEventListener("pointerdown", catchOutsidePointerDown);
    }
    document.addEventListener("keydown", closeOnEscape);

    const popupRect = popupEl.getBoundingClientRect();
    return calculatePosition(spawnRect, popupRect, placement, offset, offsetH, offsetV, yMin);
  };

  const popup: PopupState = {
    view: options.view,
    className: options.className,
    initialize,
    hasBeenInitialized: false,
    onclose: options.onclose,
    overlay: overlay,
    id: popupIdCounter++,
  };
  popups.push(popup);

  const registerChildPopup = (childPopup: CreatedPopup) => {
    // Other child popup exists, close it before registering this one.
    if (popup.childPopup) {
      popup.childPopup.close();
    }
    popup.childPopup = childPopup;
  };

  const close = () => {
    if (catchOutsidePointerDown) {
      document.removeEventListener("pointerdown", catchOutsidePointerDown);
    }
    document.removeEventListener("keydown", closeOnEscape);
    // Child popup exists, close it with this one
    if (popup.childPopup) {
      popup.childPopup.close();
      popup.childPopup = undefined;
    }
    const index = popups.indexOf(popup);
    if (index !== -1) {
      popups.splice(index, 1);
      popup.onclose?.();
    }
  };

  return { close, registerChildPopup };
};

const closeAllPopups = () => {
  for (let popup of popups) {
    popup.onclose?.();
  }
  popups = [];
};

export const PopupContainer: m.Component<{}> = {
  view() {
    let mOverlay: m.Children = null;
    const shouldDisplayOverlay = popups.some((popup) => popup.overlay === true);
    if (shouldDisplayOverlay) {
      mOverlay = m(".popup-background-overlay", { onpointerdown: closeAllPopups });
    }
    return [mOverlay, popups.map((popup) => m(PopupComponent, { popup, key: popup.id }))];
  },
};

const updatePosition = ({ dom, attrs: { popup } }: m.VnodeDOM<{ popup: PopupState }, any>) => {
  if (!popup.hasBeenInitialized) {
    const popupEl = dom as HTMLElement;
    const { x, y, maxHeight } = popup.initialize(popupEl);
    popupEl.style.left = x + "px";
    popupEl.style.top = y + "px";
    popupEl.style.maxHeight = maxHeight ? maxHeight + "px" : "auto";
    popup.hasBeenInitialized = true;
  }
};
const PopupComponent: m.Component<{ popup: PopupState }> = {
  oncreate: updatePosition,
  onupdate: updatePosition,
  view({ attrs: { popup } }) {
    return m(".popup", { className: popup.className }, popup.view());
  },
};

export const isPopupOpen = () => {
  return popups.length > 0;
};

// ----------------------------------------------------------------------------
// Popup Menus
// ----------------------------------------------------------------------------

export interface MenuItem {
  type?: string;
  label?: m.Children;
  icon?: () => m.Children;
  action?: () => void;
  accelerator?: Accelerator;
  enabled?: () => boolean;
  custom?: () => m.Children;
  tooltip?: () => m.Children;
  submenu?: () => MenuItem[];
  visible?: () => boolean;
}

export interface PopupMenuOptions {
  menuItems: MenuItem[];
  className?: string;

  spawnFrom: HTMLElement | { x: number; y: number };

  placement?: Placement;
  offset?: number;
  offsetH?: number;
  offsetV?: number;
  onclose?: () => void;
  yMin?: number;
  overlay?: Overlay;
}

export const createPopupMenu = (options: PopupMenuOptions) => {
  const view = () =>
    m(
      ".popup-menu",
      options.menuItems.map((menuItem) => {
        return m(PopupMenuItem, { menuItem, parentPopup: createdPopup });
      })
    );

  let className = options.className;
  if (className) className += " scrollable";
  else className = "scrollable";

  const createdPopup = createPopup({
    ...options,
    view,
    className,
  });
  return createdPopup;
};

interface PopupMenuItemAttrs {
  menuItem: MenuItem;
  parentPopup: CreatedPopup;
}
const PopupMenuItem: m.Component<PopupMenuItemAttrs> = {
  view(vnode) {
    const { menuItem, parentPopup } = vnode.attrs;

    if (menuItem.visible?.() === false) {
      return undefined;
    }
    if (menuItem.type === "separator") {
      return m(".popup-menu-separator");
    }

    if (menuItem.custom) {
      return menuItem.custom();
    }

    const className = classNames({
      disabled: menuItem.enabled && !menuItem.enabled(),
    });

    let onclick = () => {
      if (menuItem.enabled && !menuItem.enabled()) return;
      closeAllPopups();
      menuItem.action?.();
    };

    let onpointerenter: (() => void) | undefined;
    if (menuItem.submenu) {
      onpointerenter = () => {
        if (!menuItem.submenu) return;
        if (menuItem.enabled && !menuItem.enabled()) return;
        parentPopup.registerChildPopup(
          createPopupMenu({
            menuItems: menuItem.submenu(),
            spawnFrom: domForVnode(vnode),
            placement: "right-start",
            offsetV: 6,
          })
        );
      };
    }

    const accelerator = menuItem.accelerator ? menuItem.accelerator.toString() : "";

    const mItem = m(".popup-menu-item", { class: className, onclick, onpointerenter }, [
      m(".popup-menu-item-icon", menuItem.icon?.()),
      m(".popup-menu-item-label", menuItem.label),
      m(".popup-menu-item-accelerator", accelerator),
      menuItem.submenu && m(".popup-menu-item-icon", m(Icon20, { icon: "chevron_right" })),
    ]);

    return menuItem.tooltip
      ? m(Tooltipped, { message: menuItem.tooltip, placement: "right-start" }, mItem)
      : mItem;
  },
};

export const createRightClickPopupMenu = (event: PointerEvent, menuItems: MenuItem[]) => {
  createPopupMenu({
    menuItems,
    spawnFrom: { x: event.clientX, y: event.clientY },
    placement: "bottom-start",
  });
};

// ----------------------------------------------------------------------------
// Tooltips
// ----------------------------------------------------------------------------

interface TooltippedAttrs {
  message: () => m.Children;
  placement?: Placement;
  offset?: number;
  offsetH?: number;
  offsetV?: number;
  className?: string;
}
export const Tooltipped: m.ClosureComponent<TooltippedAttrs> = () => {
  let createdPopup: CreatedPopup | undefined = undefined;
  const closeTooltip = () => {
    if (createdPopup) createdPopup.close();
  };
  return {
    view(vnode) {
      const message = vnode.attrs.message;
      const placement = vnode.attrs.placement ?? "bottom";
      const offset = vnode.attrs.offset ?? 0;
      const offsetH = vnode.attrs.offsetH ?? 4;
      const offsetV = vnode.attrs.offsetV ?? 4;
      const children = vnode.children;

      const openTooltip = () => {
        closeTooltip();
        createdPopup = createPopup({
          spawnFrom: domForVnode(vnode),
          placement,
          offset,
          offsetH,
          offsetV,
          className: "tooltip",
          view: message,
          overlay: false,
        });
      };

      const onpointerover = (event: PointerEvent) => {
        // Don't show tooltips when a mouse button is pressed.
        if (event.buttons !== 0) return;
        openTooltip();
      };
      const onpointerup = () => {
        // Show tooltips again when the releases the mouse button.
        openTooltip();
      };
      const onpointerout = () => {
        closeTooltip();
      };

      return m(
        "span",
        {
          onpointerover,
          onpointerup,
          onpointerout,
          className: vnode.attrs.className,
        },
        children
      );
    },
    onremove() {
      closeTooltip();
      m.redraw();
    },
  };
};

// ----------------------------------------------------------------------------
// Positioning Helpers
// ----------------------------------------------------------------------------

interface Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;
  width: number;
  height: number;
}

const rectFromPoint = (x: number, y: number): Rect => {
  return {
    left: x,
    top: y,
    right: x,
    bottom: y,
    width: 0,
    height: 0,
  };
};

interface Position {
  x: number;
  y: number;
  maxHeight: number | void;
}

const calculatePosition = (
  spawn: Rect,
  popup: Rect,
  placement: Placement,
  offset: number,
  offsetH: number,
  offsetV: number,
  yMin: number
): Position => {
  let x = 0,
    y = 0;
  let isVertical = false;
  let maxHeight: void | number = undefined;

  const windowWidth = document.documentElement.clientWidth;
  const windowHeight = document.documentElement.clientHeight;

  const overflowsLeft = (x: number) => x < 0;
  const overflowsRight = (x: number) => x + popup.width > windowWidth;
  const overflowsTop = (y: number) => y < yMin;
  const overflowsBottom = (y: number) => y + popup.height > windowHeight;

  const yTop = spawn.top - popup.height - offset - offsetV;
  const yBottom = spawn.bottom + offset + offsetV;
  const xLeft = spawn.left - popup.width - offset - offsetH;
  const xRight = spawn.right + offset + offsetH;

  if (placement.includes("top")) {
    isVertical = true;
    y = yTop;
    if (overflowsTop(y)) {
      if (yBottom + popup.height <= windowHeight) {
        // Fits fully "bottom"
        y = yBottom;
      } else {
        y = Math.max(yMin, windowHeight - popup.height);
      }
    }
  } else if (placement.includes("bottom")) {
    isVertical = true;
    y = yBottom;
    if (overflowsBottom(y)) {
      if (yTop >= yMin) {
        // Fits fully "top"
        y = yTop;
      } else {
        y = Math.max(yMin, windowHeight - popup.height);
      }
    }
  } else if (placement.includes("left")) {
    x = xLeft;
    if (overflowsLeft(x)) x = xRight;
  } else if (placement.includes("right")) {
    x = xRight;
    if (overflowsRight(x)) x = xLeft;
  }

  if (isVertical) {
    const xStart = spawn.left - offsetH;
    const xMiddle = spawn.left + spawn.width / 2 - popup.width / 2;
    const xEnd = spawn.right - popup.width + offsetH;
    if (placement.includes("start")) {
      x = xStart;
      if (overflowsRight(x)) x = xEnd;
    } else if (placement.includes("end")) {
      x = xEnd;
      if (overflowsLeft(x)) x = xStart;
    } else {
      x = xMiddle;
      if (overflowsLeft(x)) x = 0;
      else if (overflowsRight(x)) x = windowWidth - popup.width;
    }
  } else {
    const yStart = spawn.top - offsetV;
    const yMiddle = spawn.top + spawn.height / 2 - popup.height / 2;
    const yEnd = spawn.bottom - popup.height + offsetV;
    if (placement.includes("start")) {
      y = yStart;
      if (overflowsBottom(y)) y = yEnd;
    } else if (placement.includes("end")) {
      y = yEnd;
      if (overflowsTop(y)) y = yStart;
    } else {
      y = yMiddle;
      if (overflowsTop(y)) y = yMin;
      else if (overflowsBottom(y)) y = windowHeight - popup.height;
    }
  }

  if (overflowsLeft(x)) {
    x = 0;
  }
  if (overflowsTop(y)) {
    y = yMin;
  }
  if (overflowsBottom(y)) {
    maxHeight = windowHeight - y;
  }

  return { x, y, maxHeight };
};

// ----------------------------------------------------------------------------
// Other Helpers
// ----------------------------------------------------------------------------

const isDescendantOf = (node: Node, grandParent: Node): boolean => {
  if (node === grandParent) return true;
  if (node.parentNode) return isDescendantOf(node.parentNode, grandParent);
  return false;
};
