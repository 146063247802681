import m from "mithril";
import { updateHead } from "./util";
import { SiteLayout } from "./shared/site-layout/site-layout";

export const NotFoundPage: m.Component<{}> = {
  view() {
    updateHead({
      title: "Cuttle - Page not found",
    });

    return m(SiteLayout, { mainClass: "center-wrapper" }, [
      m("p", "Oops! This page does not exist! 😢"),
      m(
        "p",
        "If this seems like a bug, email ",
        m("a[href=mailto:toby@cuttle.xyz]", "toby@cuttle.xyz"),
        " and we'll try to fix it!"
      ),
    ]);
  },
};
