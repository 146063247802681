import m from "mithril";
import { Tile } from "./dashboard-project-tile";
import { exploreState } from "./explore-latest";
import { accountState } from "./shared/account";
import { ProjectCardData } from "./shared/api-types";
import { Card } from "./shared/card";
import { projectCardState } from "./shared/projects-card-state";
import { SiteLayout } from "./shared/site-layout/site-layout";
import { dashboardState } from "./dashboard-state";
import { TemplateCard } from "./templates";
import { updateHead } from "./util";
import { dashboardTagState } from "./tags";

interface UniversalSearchPageAttrs {
  query: string;
}

export const UniversalSearchPage: m.Component<UniversalSearchPageAttrs> = {
  oncreate({ attrs: { query } }) {
    const inputField = document.querySelector(".query");
    if (inputField instanceof HTMLInputElement) {
      inputField.value = query;
    }
  },
  view({ attrs: { query } }) {
    updateHead({
      title: `Cuttle - Search: ${query}`,
    });

    const userProjectResults = getUserProjectResults(query);
    const templateResults = getTemplateResults(query);
    const exploreResults = getExploreResults(query);

    const mUserProjectsProjects = m(UserProjectsResults, { results: userProjectResults });
    const mTemplates = m(TemplateResults, { results: templateResults });
    const mExplore = m(ExploreResults, { results: exploreResults });

    let mNoResults;
    if (
      userProjectResults.length === 0 &&
      templateResults !== "loading" &&
      templateResults.length === 0 &&
      exploreResults !== "loading" &&
      exploreResults.length === 0
    ) {
      mNoResults = m("h2", "No Results Found");
    }

    return m(SiteLayout, [
      m(".main-inner.universal-search", [
        m("h3", "Search Results"),
        m("h1", `${query}`),
        mUserProjectsProjects,
        mTemplates,
        mExplore,
        mNoResults,
      ]),
    ]);
  },
};

interface UserProjectResultsAttrs {
  results: ProjectCardData[];
}

const getUserProjectResults = (query: string) => {
  const projects = dashboardState.projects;
  let results = projects.filter((project) => {
    return isNeedleInProject(query, project);
  });
  return results.sort((a, b) => {
    return new Date(b.lastOpenedDate!).getTime() - new Date(a.lastOpenedDate!).getTime();
  });
};

const UserProjectsResults: m.ClosureComponent<UserProjectResultsAttrs> = () => {
  let showMore = false;

  return {
    view({ attrs: { results } }) {
      let mCards;
      mCards = results.map((project) => {
        return m(Tile, { project, key: project.projectId });
      });

      if (mCards.length === 0) {
        return null;
      }

      let mButton;

      if (!showMore && mCards.length > 8) {
        mCards = mCards.slice(0, 8);
        mButton = m("button", { onclick: () => (showMore = true) }, "Show More");
      }

      return [m("h2.my-projects", "My Projects"), m(".tiles-container", [mCards]), mButton];
    },
  };
};

const getTemplateResults = (query: string) => {
  const tagsAndProjects = dashboardTagState.getTagsAndProjects();
  if (tagsAndProjects === "loading") return "loading";

  return tagsAndProjects.projects
    .filter((project) => {
      return isNeedleInProject(query, project);
    })
    .sort((a, b) => {
      const aDate = new Date(a.firstMadePublicDate!);
      const bDate = new Date(b.firstMadePublicDate!);
      return bDate.getTime() - aDate.getTime();
    });
};

interface TemplateResultsAttrs {
  results: "loading" | ProjectCardData[];
}

const TemplateResults: m.ClosureComponent<TemplateResultsAttrs> = () => {
  let showMore = false;

  return {
    view({ attrs: { results } }) {
      if (results === "loading") {
        return null;
      }

      // output results
      let mCards;
      mCards = results.map((project) => {
        const projectId = project.projectId;
        return m(TemplateCard, { projectId, key: projectId });
      });

      if (mCards.length === 0) {
        return null;
      }

      let mButton;

      if (!showMore && mCards.length > 8) {
        mCards = mCards.slice(0, 8);
        mButton = m("button", { onclick: () => (showMore = true) }, "Show More");
      }

      return [m("h2.templates", "Templates"), m(".card-grid", [mCards]), mButton];
    },
  };
};

interface ExploreResultsAttrs {
  results: "loading" | ProjectCardData[];
}

const getExploreResults = (query: string) => {
  const projects = exploreState.getProjects();
  if (projects === "loading") return "loading";

  const results = projects.filter((project) => {
    // Don't list templates
    if (project.ownerUsername === "cuttle") return false;
    return isNeedleInProject(query, project);
  });

  // Sort results: projects with cover photos should be shown first
  results.sort((a, b) => {
    if (a.coverPhoto && !b.coverPhoto) {
      return -1;
    } else if (b.coverPhoto && !a.coverPhoto) {
      return 1;
    } else {
      return (
        new Date(b.firstMadePublicDate!).getTime() - new Date(a.firstMadePublicDate!).getTime()
      );
    }
  });

  return results;
};

const ExploreResults: m.ClosureComponent<ExploreResultsAttrs> = () => {
  let showMore = false;

  return {
    view({ attrs: { results } }) {
      if (results === "loading") {
        return null;
      }

      // output results
      let mCards;
      mCards = results.map((project) => {
        return m(Card, { project, key: project.projectId });
      });

      if (mCards.length === 0) {
        return null;
      }

      let mButton;

      if (!showMore && mCards.length > 8) {
        mCards = mCards.slice(0, 8);
        mButton = m("button", { onclick: () => (showMore = true) }, "Show More");
      }

      return [m("h2.community-projects", "Community Projects"), m(".card-grid", [mCards]), mButton];
    },
  };
};

const isNeedleInProject = (needle: string, project: ProjectCardData): boolean => {
  if (isNeedleInHaystack(needle, project.name)) return true;
  if (isNeedleInHaystack(needle, project.ownerUsername)) return true;
  if (project.ownerDisplayName && isNeedleInHaystack(needle, project.ownerDisplayName)) return true;
  if (project.publishedDescription && isNeedleInHaystack(needle, project.publishedDescription))
    return true;

  return false;
};

const isNeedleInHaystack = (needle: string, haystack: string): boolean => {
  // Remove common words like "the" from the needle
  const commonWords = ["the", "a", "an", "and", "or", "but"];
  const words = needle
    .toLowerCase()
    .split(" ")
    .filter((word) => !commonWords.includes(word));

  // Check if any of the words from the needle are in the haystack
  return words.some((word) => haystack.toLowerCase().includes(word));
};
