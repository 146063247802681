// Note: this file is called analy-tics.ts instead of analytics.ts so that our
// ad blockers don't block it when developing locally. (When built, the file
// will be bundled so it doesn't matter what it's called.)

import { logEvent } from "./log-event";

const landingUrl = window.location.href;

export const analyticsIdentify = (
  userId: number,
  username: string,
  email: string,
  displayName: string | undefined
) => {
  // No-op, but I'm leaving this here in case we use another analytics system -Toby
};
export const analyticsUnidentify = () => {
  // No-op, but I'm leaving this here in case we use another analytics system -Toby
};

export const analyticsPageLanding = () => {
  const url = window.location.href;
  const referrer = document.referrer;
  const device = inferDeviceType();
  logEvent("pageview", { url, referrer, device });
  analyticsRewardfulVisit();
};

const inferDeviceType = () => {
  const userAgent = navigator.userAgent;
  if (/ipad|tablet/i.test(userAgent)) return "tablet";
  if (/mobi|android|phone/i.test(userAgent)) return "mobile";
  return "desktop";
};

export const analyticsPageView = () => {
  logEvent("pageview", { url: window.location.href });
};

const analyticsRewardfulVisit = () => {
  (window as any).rewardful?.("ready", () => {
    const Rewardful = (window as any).Rewardful;
    const url = window.location.href;
    const referrer = document.referrer;
    const referral = Rewardful.referral;
    const affiliate = Rewardful.affiliate.id;
    if (referral) {
      logEvent("rewardful visit", {
        referral,
        affiliate,
        url,
        referrer,
      });
    }
  });
};

export const metadataForNewAccount = () => {
  const Rewardful = (window as any).Rewardful;
  return {
    url: window.location.href,
    device: inferDeviceType(),
    referrer: document.referrer,
    landingUrl,
    referral: Rewardful?.referral || undefined,
    affiliate: Rewardful?.affiliate?.id || undefined,
  };
};

export const rewardfulReferral = (): string | undefined => {
  return (window as any).Rewardful?.referral ?? undefined;
};
