import m from "mithril";
import { fetchText, markdownToHtml, updateHead } from "./util";
import { Sidebar } from "./sidebar";
import { SiteLayout } from "./shared/site-layout/site-layout";

interface MarkdownPage {
  title: string;
  content: string;
  sidebar?: boolean;
}

export const markdownPages: { [url: string]: MarkdownPage } = {
  "/pricing": {
    title: "Pricing",
    content: "/content/pricing.md",
  },
  "/legal/privacy-policy": {
    title: "Privacy Policy",
    content: "/content/legal/privacy-policy.md",
  },
  "/legal/terms-of-service": {
    title: "Terms of Service",
    content: "/content/legal/terms-of-service.md",
  },

  // Learn
  "/learn/basics/getting-started": {
    title: "Getting Started",
    content: "/content/learn/getting-started.md",
    sidebar: true,
  },
  "/learn/basics/understanding-the-cuttle-interface": {
    title: "Understanding the Cuttle Interface",
    content: "/content/learn/understanding-the-cuttle-interface.md",
    sidebar: true,
  },
  "/learn/basics/pen-tool-and-path-editing": {
    title: "Pen Tool and Path Editing",
    content: "/content/learn/pen-tool-and-path-editing.md",
    sidebar: true,
  },
  "/learn/basics/guides": {
    title: "Guides",
    content: "/content/learn/guides.md",
    sidebar: true,
  },
  "/learn/common-questions/licensing": {
    title: "Licensing",
    content: "/content/learn/licensing.md",
    sidebar: true,
  },
  "/learn/common-questions/adding-your-own-fonts": {
    title: "Adding Your Own Fonts",
    content: "/content/learn/adding-your-own-fonts.md",
    sidebar: true,
  },
  "/learn/common-questions/how-to-score-on-a-laser-cutter": {
    title: 'How to "Score" on a Laser Cutter',
    content: "/content/learn/how-to-score-on-a-laser-cutter.md",
    sidebar: true,
  },
  "/learn/common-questions/changing-units": {
    title: "Changing Units",
    content: "/content/learn/changing-units.md",
    sidebar: true,
  },
  "/learn/common-questions/copying-components-from-one-project-to-another": {
    title: "Copying Components",
    content: "/content/learn/copying-components-from-one-project-to-another.md",
    sidebar: true,
  },
  "/learn/common-questions/cuttle-system-requirements": {
    title: "Cuttle System Requirements",
    content: "/content/learn/cuttle-system-requirements.md",
    sidebar: true,
  },
  "/learn/repetitions/customized-repetitions": {
    title: "Customized Repetitions",
    content: "/content/learn/customized-repetitions.md",
    sidebar: true,
  },
  "/learn/parameters/getting-started-with-parameters": {
    title: "Getting Started with Parameters",
    content: "/content/learn/getting-started-with-parameters.md",
    sidebar: true,
  },
  "/learn/parameters/math-reference": {
    title: "Math Reference",
    content: "/content/learn/math-reference.md",
    sidebar: true,
  },
  "/learn/parameters/vector-reference": {
    title: "Vector Reference",
    content: "/content/learn/vector-reference.md",
    sidebar: true,
  },
  "/learn/scripting/getting-started-with-scripting": {
    title: "Getting Started with Scripting",
    content: "/content/learn/getting-started-with-scripting.md",
    sidebar: true,
  },
  "/learn/scripting/code-components": {
    title: "Code Components",
    content: "/content/learn/code-components.md",
    sidebar: true,
  },
  "/learn/scripting/scripting-fundamentals": {
    title: "Scripting Fundamentals",
    content: "/content/learn/scripting-fundamentals.md",
    sidebar: true,
  },
  "/learn/scripting/stroke-and-fill-style": {
    title: "Stroke and Fill Style",
    content: "/content/learn/stroke-and-fill-style.md",
    sidebar: true,
  },
  "/learn/scripting/calling-other-components-and-modifiers": {
    title: "Calling Other Components and Modifiers",
    content: "/content/learn/calling-other-components-and-modifiers.md",
    sidebar: true,
  },
  "/learn/scripting/working-with-paths": {
    title: "Working with Paths",
    content: "/content/learn/working-with-paths.md",
    sidebar: true,
  },
  "/learn/scripting/intersections": {
    title: "Intersections",
    content: "/content/learn/intersections.md",
    sidebar: true,
  },
};

interface MarkdownPageAttrs {
  page: MarkdownPage;
}

export const MarkdownPage: m.Component<MarkdownPageAttrs> = {
  view({ attrs: { page } }) {
    updateHead({
      title: "Cuttle - " + page.title,
    });

    let mContents;

    const md = fetchText(page.content);
    if (md === undefined) {
      // Loading
    } else {
      const html = markdownToHtml(md, page.content);
      mContents = m.trust(html);
    }

    let mMain;

    if (page.sidebar) {
      return m(SiteLayout, { hamburgerExtra: () => m(Sidebar) }, [
        m(".white-background-when-small", [
          m(".main-inner", [
            m(".with-sidebar", [
              m(Sidebar),
              m("article", [
                //
                m("h1.title", page.title),
                mContents,
              ]),
            ]),
          ]),
        ]),
      ]);
    } else {
      return m(SiteLayout, [
        m(".main-inner", [
          m("article", [
            //
            m("h1.title", page.title),
            mContents,
          ]),
        ]),
      ]);
    }

    return m(SiteLayout, mMain);
  },
};
