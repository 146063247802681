import m from "mithril";
import { accountState } from "./shared/account.js";
import { apiRequest } from "./shared/api-request.js";
import { formatDateAndTime } from "./shared/util.js";
import { enforceLogin, updateHead } from "./util.js";

export const StatsForCanceledPage: m.ClosureComponent<{}> = () => {
  const eventName = "subscription canceled";
  let statsResult: any;

  return {
    oninit() {
      const getStatsByEventName = async () => {
        const responseData = await apiRequest("getStatsByEventName", { eventName });
        if (responseData.success) {
          statsResult = responseData as any;
        }
      };
      getStatsByEventName();
    },
    view(vnode) {
      if (!accountState.loggedInUser) {
        return enforceLogin();
      }

      updateHead({
        title: "Cuttle - Recently Canceled",
      });

      if (!statsResult) {
        // Still loading
        return null;
      }

      let eventRows = statsResult.eventRows;

      const mTable = m("table", [
        m("tr", [m("th", "Timestamp"), m("th", "Link")]),

        eventRows.map((eventRow: any) => {
          const timestamp = formatDateAndTime(eventRow.timestamp);
          const email = eventRow.email;
          const link = "/stats/user?email=" + email;

          return m("tr", [m("td", timestamp), m("td", [m(m.route.Link, { href: link }, email)])]);
        }),
      ]);
      return m(".stats-page", [m("h1", `😿 Cancellations 😿`), m(".stats-table", mTable)]);
    },
  };
};
