import m from "mithril";
import { Card } from "./shared/card";
import { projectCardState } from "./shared/projects-card-state";

export const TemplatesSection: m.Component<{ projectIds: string[] }> = {
  view({ attrs: { projectIds } }) {
    let mCards = projectIds.map((projectId) => {
      return m(TemplateCard, { projectId });
    });
    return m(".card-grid", [mCards]);
  },
};

interface TemplateCardAttrs {
  projectId: string;
}
export const TemplateCard: m.Component<TemplateCardAttrs> = {
  view({ attrs: { projectId } }) {
    const project = projectCardState.getProject(projectId);
    if (project === "loading") return null;

    const price = project.isPro ? "pro" : "free";
    return m(Card, { project, price, hideOwner: true });
  },
};
