/**
 * Note that project links use `"a"` for full page reloads, while dashboard links
 * (like user profile) use `m.route.Link` for Mithril "single page app" routing.
 */

import m from "mithril";
import { ProjectCardData } from "./api-types";
import { AvatarImage } from "./avatar-image";
import { transparentPngUrl, canonicalUrlForProject, imgixThumbnailAttrs, formatDate } from "./util";

const dateForProjectCard = (project: ProjectCardData) => {
  const { creationDate, firstMadePublicDate, publishedDate } = project;
  if (firstMadePublicDate) {
    const firstMadePublicDateString = formatDate(firstMadePublicDate);
    if (publishedDate) {
      const publishedDateString = formatDate(publishedDate);
      if (firstMadePublicDateString !== publishedDateString) {
        return `${firstMadePublicDateString} (updated ${publishedDateString})`;
      }
    }
    return firstMadePublicDateString;
  }
  return formatDate(creationDate);
};

const urlForProjectCard = (project: ProjectCardData) => {
  return canonicalUrlForProject(project.ownerUsername, project.name, project.projectId);
};

interface CardAttrs {
  project: ProjectCardData;
  openLinksInNewTab?: boolean;
  price?: "free" | "pro";
  hideOwner?: boolean;
}
export const Card: m.Component<CardAttrs> = {
  view({ attrs: { project, openLinksInNewTab, price, hideOwner } }) {
    return m(".card", [
      m(CardCover, { project, openLinksInNewTab }),
      m(".card-info", [
        m(CardTitleRow, { project, openLinksInNewTab, price }),
        project.publishedDescription ? m(".card-description", project.publishedDescription) : null,
        hideOwner ? null : m(CardOwnerDateRow, { project, openLinksInNewTab }),
      ]),
    ]);
  },
};

interface CardCoverAttrs {
  project: ProjectCardData;
  openLinksInNewTab?: boolean;
}
const CardCover: m.Component<CardCoverAttrs> = {
  view({ attrs: { project, openLinksInNewTab } }) {
    const alt = project.name || "Untitled Project";
    let mCardImage;
    if (project.coverPhoto) {
      const { src, srcset } = imgixThumbnailAttrs(project.coverPhoto);
      mCardImage = m("img.card-image", { alt, src, srcset });
    } else {
      // New projects without any saves will have a missing image
      const handleMissingImage = (event: Event) => {
        (event.target as HTMLImageElement).src = transparentPngUrl;
      };
      mCardImage = m("img.card-image", {
        alt,
        src: project.previewImage,
        onerror: handleMissingImage,
      });
    }

    return m(
      "a", // Project links use `"a"` for full page reloads
      {
        href: urlForProjectCard(project),
        target: openLinksInNewTab ? "_blank" : undefined,
      },
      [m(".container-16-by-9.card-cover", [mCardImage])]
    );
  },
};

interface CardTitleRowAttrs {
  project: ProjectCardData;
  openLinksInNewTab?: boolean;
  price?: string;
}
const CardTitleRow: m.Component<CardTitleRowAttrs> = {
  view({ attrs: { project, openLinksInNewTab, price } }) {
    const title = project.name || "Untitled Project";
    let mPrice;
    if (price === "free") {
      mPrice = m(".feature-tag.card-price-free", "free");
    } else if (price === "pro") {
      mPrice = m(".feature-tag.card-price-pro", "pro");
    }
    return m(".card-title-row", [
      m(
        "a", // Project links use `"a"` for full page reloads
        {
          href: urlForProjectCard(project),
          target: openLinksInNewTab ? "_blank" : undefined,
        },
        title
      ),
      mPrice,
    ]);
  },
};

interface CardInfoRowAttrs {
  project: ProjectCardData;
  openLinksInNewTab?: boolean;
}
const CardOwnerDateRow: m.Component<CardInfoRowAttrs> = {
  view({ attrs: { project, openLinksInNewTab } }) {
    const owner = project.ownerDisplayName || `@${project.ownerUsername}`;
    const ownerUrl = `/@${project.ownerUsername}`;
    const linkAttrs = {
      href: ownerUrl,
      target: openLinksInNewTab ? "_blank" : undefined,
    };

    return m(".card-owner-date-row", [
      m(
        m.route.Link,
        {
          ...linkAttrs,
          // Because the link and user name are repeated as text, the image link
          // can be considered decorative
          "aria-hidden": "true",
        },
        [
          m(AvatarImage, {
            avatarUrl: project.ownerAvatar,
            username: project.ownerUsername,
            size: 35,
          }),
        ]
      ),
      m(".card-owner-date-right-side", [
        m(".card-owner", [m(m.route.Link, linkAttrs, owner)]),
        m(".card-date", dateForProjectCard(project)),
      ]),
    ]);
  },
};
