import m from "mithril";

/** Show `IntroTile` after your projects, when you only have a few, in dashboard home. */
export const IntroTile: m.Component<{}> = {
  view() {
    return m(".tile-container", [
      m(".tile-share-status-container", m(".tile-private", "Intro")),
      m(
        "a",
        { href: "/intro", target: "_blank" },
        m(
          ".container-16-by-9",
          m("img.tile", { src: "/images/tutorials/learn-the-basics.jpg", alt: "" })
        ),
        m("div.tile-info-container", m("div.tile-filename", "Learn the Basics"))
      ),
    ]);
  },
};
