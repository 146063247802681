import m from "mithril";

import { accountState } from "../account";
import { AvatarImage } from "../avatar-image";
import { blogOrigin } from "../config";
import { Icon20, IconSVG } from "../icon";
import { logEvent } from "../log-event";
import { createPopupMenu } from "../popup";
import { goToDashboard } from "../util";
import { HamburgerMenu, NavLogo } from "./hamburger";

export const Nav: m.Component<{
  hamburgerExtra?: () => m.Children;
}> = {
  view(vnode) {
    const { hamburgerExtra } = vnode.attrs;

    const loggedInUsername = accountState.loggedInUser?.username;
    const isLoggedIn = accountState.loggedInUser !== undefined;

    let mCommunity;

    if (accountState.loggedInUser?.subscription.plan !== "K-12 Education") {
      mCommunity = m(".nav-link.nav-with-dropdown", [
        m("div", { style: "display: flex" }, [
          m("div", "Community"),
          m("div", m(Icon20, { icon: "chevron_down" })),
        ]),
        m(".nav-dropdown", [
          m(".nav-dropdown-items", [
            m(
              "a",
              { href: "https://www.facebook.com/groups/cuttle", target: "_blank" },
              "Facebook Group"
            ),
            m("a", { href: "/discord", target: "_blank" }, "Discord Server"),
          ]),
        ]),
      ]);
    }

    let mLeftNav;
    mLeftNav = m(".left-nav", [
      m(".nav-links-container", [
        m(NavLogo),
        isLoggedIn
          ? m(m.route.Link, { class: "nav-link", href: "/dashboard" }, "My Projects")
          : null,
        m(m.route.Link, { class: "nav-link", href: "/templates" }, "Templates"),
        m(m.route.Link, { class: "nav-link", href: "/explore" }, "Explore"),
        m(m.route.Link, { class: "nav-link", href: "/learn/video-tutorials" }, "Learn"),
        m("a.nav-link", { href: blogOrigin }, "Blog"),
        m(
          m.route.Link,
          {
            class: "nav-link",
            href: "/pricing",
            onclick: () => logEvent("clicked pricing", { from: "nav" }),
          },
          "Pricing"
        ),
        mCommunity,
      ]),
    ]);

    let mRightNav;

    if (!isLoggedIn) {
      // show log in and sign up
      mRightNav = m(".right-nav", [
        m(
          // TODO: make these buttons once modals are keyboard accessible
          ".login.nav-link",
          { onclick: () => accountState.openAccountModal("login", goToDashboard) },
          "Log In"
        ),
        m(
          ".nav-signup-button.nav-link",
          { onclick: () => accountState.openAccountModal("signup", goToDashboard) },
          "Sign Up"
        ),
      ]);
    } else {
      const openMenu = () => {
        createPopupMenu({
          menuItems: [
            {
              custom: () =>
                m(".nav-logged-in-user", [
                  m("div", "Signed in as:"),
                  m("div", `@${loggedInUsername}`),
                ]),
            },
            { type: "separator" },
            {
              label: "Profile Page",
              action: () => m.route.set(`/@${loggedInUsername}`),
            },
            {
              label: "Account Settings",
              action: () => m.route.set("/settings"),
            },
            { type: "separator" },
            { label: "Log Out", action: () => m.route.set("/logout") },
          ],
          spawnFrom: (vnode as any).dom.querySelector(".nav-menu") as HTMLElement,
          placement: "bottom-end",
        });
      };
      mRightNav = m(".right-nav", [
        m(".nav-menu", { onclick: openMenu }, [
          m(AvatarImage, {
            avatarUrl: accountState.loggedInUser!.avatar,
            username: accountState.loggedInUser!.username,
            size: 45,
          }),
          m(".nav-menu-dropdown-icon", m(Icon20, { icon: "chevron_down" })),
        ]),
      ]);
    }

    return m(".nav", [
      //
      m(".nav-inner", [
        //
        mLeftNav,
        m(HamburgerMenu, { hamburgerExtra }),
        m(SearchBar),
        mRightNav,
      ]),
    ]);
  },
};

export const SearchBar: m.Component<{}> = {
  view(vnode) {
    const openSearchPage = async (event: Event) => {
      event.preventDefault();

      const el: HTMLElement = (vnode as any).dom;
      const inputEl = el.querySelector(".query") as HTMLInputElement;
      const query = inputEl.value;

      if (!query) {
        return;
      }

      logEvent("universal search", { query });
      inputEl.blur(); // Closes the keyboard on mobile.
      const escapedQuery = encodeURIComponent(query);

      m.route.set(`/search/${escapedQuery}`);
    };

    return m(".search-bar", [
      m("form", { onsubmit: openSearchPage }, [
        m("input[type=text][enterkeyhint=search].query", {
          "aria-label": "Search",
          placeholder: "Search",
        }),
      ]),
    ]);
  },
};
