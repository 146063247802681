import m from "mithril";
import { accountState } from "./account";
import { apiRequest } from "./api-request";
import { GoogleSignInButton } from "./google-sign-in";
import { logEvent } from "./log-event";
import { modalState, ModalX } from "./modal";
import { metadataForNewAccount } from "./analy-tics";

const mPolicyAgreement = m(
  ".policy-agreement",
  m.trust(
    "By continuing, you agree to Cuttle's <a href='/legal/terms-of-service' target='_blank'>terms of service</a> and <a href='/legal/privacy-policy' target='_blank'>privacy policy</a>."
  )
);

interface AccountModalAttrs {
  onSuccess?: () => void;
  onDismiss?: () => void;
}

export const LoginModal: m.ClosureComponent<AccountModalAttrs> = () => {
  let validCredentials = true;
  let emailOrUsernameProvided = true;
  let passwordProvided = true;
  return {
    view(vnode) {
      const { onSuccess, onDismiss } = vnode.attrs;
      const login = async (event: Event) => {
        event.preventDefault();

        validCredentials = true;
        emailOrUsernameProvided = true;
        passwordProvided = true;

        const el: HTMLElement = (vnode as any).dom;

        const emailOrUsername = (el.querySelector(".login-email") as HTMLInputElement).value;
        const password = (el.querySelector(".login-password") as HTMLInputElement).value;

        if (!emailOrUsername) {
          emailOrUsernameProvided = false;
          return;
        }
        if (!password) {
          passwordProvided = false;
          return;
        }

        const responseData = await apiRequest("login", { emailOrUsername, password });

        if (responseData.success) {
          validCredentials = true;
          await accountState.refreshLoggedInUser();
          logEvent("sign in");
          modalState.close();
          onSuccess?.();
        } else {
          validCredentials = false;
        }
      };

      let mError = m("p.account-error", " ");

      if (!emailOrUsernameProvided) {
        mError = m("p.account-error", "Please provide your email address or username.");
      }

      if (!passwordProvided) {
        mError = m("p.account-error", "Please provide your password.");
      }

      if (!validCredentials) {
        mError = m(
          "p.account-error",
          "We don't have that email / username and password combination on file."
        );
      }

      return m(".modal-box.account-modal", [
        m(ModalX),
        m("form.form-wrapper", { onsubmit: login }, [
          m(GoogleSignInButton, { onSuccess }),
          m(".account-modal-or", "or continue with email"),
          m(".label-input", [
            m("label.label", { for: "login-email" }, "Email / Username"),
            m("input.login-email", { type: "text", id: "login-email" }),
          ]),
          m(".label-input", [
            m("label.label", { for: "login-password" }, "Password"),
            m("input.login-password", { type: "password", id: "login-password" }),
          ]),
          m("button", "Log In"),
          mError,
          m("p", [
            m(
              "span[role=link]",
              {
                onclick: () =>
                  accountState.openAccountModal("forgotPassword", onSuccess, onDismiss),
              },
              "Forgot Password?"
            ),
          ]),
          m("p", [
            m("span", "No account? "),
            m(
              "span[role=link]",
              {
                onclick: () => accountState.openAccountModal("signup", onSuccess, onDismiss),
              },
              "Create an account"
            ),
            ".",
          ]),
          mPolicyAgreement,
        ]),
      ]);
    },
  };
};

export const SignupModal: m.ClosureComponent<AccountModalAttrs> = () => {
  let showEmailOption = false;
  let emailExists = false;
  let usernameExists = false;
  let usernameInvalid = false;
  let passwordCorrectLength = true;
  let emailProvided = true;
  let usernameProvided = true;
  let passwordProvided = true;
  return {
    view(vnode) {
      const { onSuccess, onDismiss } = vnode.attrs;
      const createAccount = async (event: Event) => {
        event.preventDefault();

        emailProvided = true;
        usernameProvided = true;
        passwordProvided = true;
        emailExists = false;
        usernameExists = false;
        usernameInvalid = false;
        passwordCorrectLength = true;

        const el: HTMLElement = (vnode as any).dom;

        const email = (el.querySelector(".create-account-email") as HTMLInputElement).value;
        const username = (el.querySelector(".create-account-username") as HTMLInputElement).value;
        const password = (el.querySelector(".create-account-password") as HTMLInputElement).value;

        if (!email) {
          emailProvided = false;
          return;
        }
        if (!username) {
          usernameProvided = false;
          return;
        }
        if (!password) {
          passwordProvided = false;
          return;
        }

        const responseData = await apiRequest("createAccount", {
          email,
          username,
          password,
          metadata: metadataForNewAccount(),
        });

        if (responseData.success) {
          await accountState.refreshLoggedInUser();
          logEvent("sign up with email");
          modalState.close();
          onSuccess?.();
        } else {
          if (responseData.message === "Email not available") {
            emailExists = true;
          }
          if (responseData.message === "Username not available") {
            usernameExists = true;
          }
          if (responseData.message === "Username is invalid") {
            usernameInvalid = true;
          }
          if (responseData.message === "Password is less than 8 characters") {
            passwordCorrectLength = false;
          }
        }
      };

      const clickShowEmailOption = () => {
        showEmailOption = true;
      };

      // TODO: username / email is available messages when user stops typing for x amount of time

      let mError = m("p.account-error", " ");

      if (!emailProvided) {
        mError = m("p.account-error", "Please provide your email address");
      }
      if (!usernameProvided) {
        mError = m("p.account-error", "Please provide your username");
      }
      if (!passwordProvided) {
        mError = m("p.account-error", "Please provide your password");
      }
      if (emailExists) {
        mError = m("p.account-error", "Email not available");
        emailExists = false;
      }
      if (usernameExists) {
        mError = m("p.account-error", "Username not available");
        usernameExists = false;
      }
      if (usernameInvalid) {
        mError = m(
          "p.account-error",
          "Username must be less than 15 characters and can only contain letters, numbers and '_'"
        );
      }
      if (!passwordCorrectLength) {
        mError = m("p.account-error", "Password must contain a minimum of 8 characters");
      }

      return m(".modal-box.account-modal", [
        m(ModalX),
        m("form.form-wrapper", { onsubmit: createAccount }, [
          m(GoogleSignInButton, { onSuccess }),
          showEmailOption
            ? [
                m(".account-modal-or", "or continue with email"),
                m(".label-input", [
                  m("label.label", { for: "create-account-email" }, "Email"),
                  m("input.create-account-email", { type: "email", id: "create-account-email" }),
                ]),
                m(".label-input", [
                  m("label.label", { for: "create-account-username" }, "Username"),
                  m("input.create-account-username", {
                    type: "text",
                    id: "create-account-username",
                    maxLength: "15",
                  }),
                ]),
                m(".label-input", [
                  m("label.label", { for: "create-account-password" }, "Password"),
                  m("input.create-account-password", {
                    type: "password",
                    id: "create-account-password",
                  }),
                ]),
                m("button", { type: "submit" }, "Create Account"),
              ]
            : [
                m(".account-modal-or", "or"),
                m(
                  "button.secondary.show-email-signup",
                  { onclick: clickShowEmailOption },
                  "Create Account with Email"
                ),
              ],

          mError,
          m(".account-call-to-action", [
            m("p", [
              m("span", "Have an account? "),
              m(
                "span[role=link]",
                {
                  onclick: () => accountState.openAccountModal("login", onSuccess, onDismiss),
                },
                "Log in"
              ),
              ".",
            ]),
          ]),
          mPolicyAgreement,
        ]),
      ]);
    },
  };
};

export const ForgotPasswordModal: m.ClosureComponent<AccountModalAttrs> = () => {
  let forgotPasswordSent = false;
  return {
    view(vnode) {
      const { onSuccess, onDismiss } = vnode.attrs;
      const forgotPassword = async (event: Event) => {
        event.preventDefault();

        forgotPasswordSent = false;

        const el: HTMLElement = (vnode as any).dom;

        const email = (el.querySelector(".send-reset-email") as HTMLInputElement).value;

        const responseData = await apiRequest("sendResetToken", { email });

        if (responseData.success) {
          forgotPasswordSent = true;
        } else {
          forgotPasswordSent = true;
        }
      };

      let mForgotPassword;

      if (!forgotPasswordSent) {
        mForgotPassword = m("form.form-wrapper", { onsubmit: forgotPassword }, [
          m("h2", "Reset Password"),
          m(".label-input", [
            m("label.label", { for: "send-reset-email" }, "Email"),
            m("input.send-reset-email", { type: "email", id: "send-reset-email" }),
          ]),
          m("button", "Send Reset Email"),
          m("p.account-error", " "), // this is to match the space after the buttons for login and signup
          m(".account-call-to-action", [
            m("p", [
              m("span", "or "),
              m(
                "span[role=link]",
                { onclick: () => accountState.openAccountModal("login", onSuccess, onDismiss) },
                "Log In"
              ),
            ]),
          ]),
        ]);
      }

      if (forgotPasswordSent) {
        mForgotPassword = m(
          "p.reset-password-sent",
          "Check your email for further instructions. 📬"
        );
      }

      return m(".modal-box.account-modal", [m(ModalX), mForgotPassword]);
    },
  };
};
