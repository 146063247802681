import m from "mithril";
import { accountState } from "../account";
import { blogOrigin } from "../config";
import { logEvent } from "../log-event";
import instagram from "./social-icons/instagram.svg";
import twitter from "./social-icons/twitter.svg";
import youtube from "./social-icons/youtube.svg";

export const Footer: m.Component<{}> = {
  view() {
    let mCommunity;
    if (accountState.loggedInUser?.subscription.plan !== "K-12 Education") {
      mCommunity = [
        m(
          "a.footer-link",
          {
            href: "https://www.facebook.com/groups/cuttle",
            target: "_blank",
          },
          "Facebook Group"
        ),
        m("a.footer-link", { href: "/discord", target: "_blank" }, "Discord Server"),
      ];
    }

    return m("footer", [
      m(".footer-inner", [
        m(".footer-content", [
          m(".footer-columns", [
            m(".left-footer-columns", [
              m(".footer-links-container", [
                m(m.route.Link, { class: "footer-link", href: "/templates" }, "Templates"),
                m(m.route.Link, { class: "footer-link", href: "/explore" }, "Explore"),
                m(m.route.Link, { class: "footer-link", href: "/learn/video-tutorials" }, "Learn"),
                m("a.footer-link", { href: blogOrigin }, "Blog"),
                m(
                  m.route.Link,
                  {
                    class: "footer-link",
                    href: "/pricing",
                    onclick: () => logEvent("clicked pricing", { from: "footer" }),
                  },
                  "Pricing"
                ),
                mCommunity,
                m(
                  "a.footer-link",
                  {
                    href: "/affiliate",
                    target: "_blank",
                    onclick: () => logEvent("clicked affiliate", { from: "footer" }),
                  },
                  "Affiliate Program"
                ),
              ]),
              m(".second-column", [
                m(".social-container", [
                  m(".social-icons", [
                    m(
                      "a",
                      {
                        href: "https://twitter.com/CuttleXYZ",
                        target: "_blank",
                        "aria-label": "Twitter: @CuttleXYZ",
                      },
                      m.trust(twitter)
                    ),
                    m(
                      "a",
                      {
                        href: "https://www.youtube.com/@Cuttle",
                        target: "_blank",
                        "aria-label": "YouTube: @Cuttle",
                      },
                      m.trust(youtube)
                    ),
                    m(
                      "a",
                      {
                        href: "https://www.instagram.com/cuttlexyz/",
                        target: "_blank",
                        "aria-label": "Instagram: @CuttleXYZ",
                      },
                      m.trust(instagram)
                    ),
                  ]),
                  m(".contact-container", [
                    m(".contact", "Need help?"),
                    "Email ",
                    m(
                      "a.footer-link",
                      { href: "mailto: support@cuttle.xyz" },
                      "support@cuttle.xyz"
                    ),
                  ]),
                ]),
                m(".legal-container", [
                  m("div", `© ${new Date().getFullYear()} - Cuttle Labs, Inc.`),
                  m(
                    m.route.Link,
                    { class: "footer-link", href: "/legal/terms-of-service" },
                    "Terms of Service"
                  ),
                  m(
                    m.route.Link,
                    { class: "footer-link", href: "/legal/privacy-policy" },
                    "Privacy Policy"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]);
  },
};
