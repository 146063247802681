import dayjs from "dayjs";
import m from "mithril";
import { accountState } from "./shared/account.js";
import { apiRequest } from "./shared/api-request.js";
import { formatDateAndTime } from "./shared/util.js";
import { enforceLogin, updateHead } from "./util.js";

export const StatsUserPage: m.ClosureComponent<{}> = () => {
  const queryStringObject = m.parseQueryString(window.location.search);
  const email = "" + queryStringObject.email;
  let statsResult: any;

  return {
    oninit() {
      const getStatsForUser = async () => {
        const responseData = await apiRequest("getStatsForUser", { email });
        if (responseData.success) {
          statsResult = responseData as any;
        }
      };
      getStatsForUser();
    },
    view(vnode) {
      if (!accountState.loggedInUser) {
        return enforceLogin();
      }

      updateHead({
        title: "Cuttle - Stats for User",
      });

      if (!statsResult) {
        // Still loading
        return null;
      }

      let eventRows = statsResult.eventRows;

      const mTable = m("table", [
        m("tr", [
          m("th", "Timestamp"),
          m("th", "Project Name"),
          m("th", "Event"),
          m("th", "Metadata"),
        ]),

        eventRows.map((eventRow: any) => {
          const timestamp = formatDateAndTime(eventRow.timestamp);
          const projectName = eventRow.projectName;
          const shareStatus = eventRow.projectShareStatus;
          const eventName = eventRow.eventName;

          let shareStatusEmoji;
          if (shareStatus === "public") {
            shareStatusEmoji = "🌎";
          } else if (shareStatus === "unlisted") {
            shareStatusEmoji = "🕵️";
          } else if (shareStatus === "private") {
            shareStatusEmoji = "🔒";
          } else {
            shareStatusEmoji = "";
          }

          return m("tr", [
            m("td.stats-no-break", timestamp),
            m("td.stats-no-break", shareStatusEmoji, " ", projectName),
            m("td.stats-no-break", eventName),
            m("td", m(Metadata, { metadata: eventRow.metadata })),
          ]);
        }),
      ]);
      return m(".stats-page", [m("h1", `⭐️ ${email} ⭐️`), m(".stats-table", mTable)]);
    },
  };
};

interface MetadataAttrs {
  metadata: any;
}

const Metadata: m.Component<MetadataAttrs> = {
  view({ attrs: { metadata } }) {
    if (metadata === null || Object.keys(metadata).length === 0) {
      return;
    }

    const metadataString = JSON.stringify(metadata);
    if (metadataString.length > 30) {
      return m("details", [m("summary", metadataString.slice(0, 30)), metadataString]);
    } else {
      return metadataString;
    }
  },
};
