import m from "mithril";
import { PreviewImage } from "./dashboard-project-tile";
import { dashboardState } from "./dashboard-state";

export const DashboardDragOverlay: m.Component<{}> = {
  view() {
    // Dragging project tile
    const project = dashboardState.projectBeingDragged;

    let mGhostImage;
    if (project) {
      mGhostImage = m(".dashboard-ghost-image", [m(PreviewImage, { project })]);
    }

    // Dragging folder
    const folder = dashboardState.folderBeingDragged;

    let mGhostFolder;
    if (folder) {
      mGhostFolder = m(".dashboard-ghost-folder", folder.name);
    }

    return m(".dashboard-drag-overlay", [
      m(
        ".dashboard-drag-ghost-positioner",
        {
          style: {
            top: dashboardState.dragY + "px",
            left: dashboardState.dragX + "px",
          },
        },
        [mGhostImage, mGhostFolder]
      ),
    ]);
  },
};
