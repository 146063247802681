import m from "mithril";
import { accountState } from "./shared/account";
import { apiRequest } from "./shared/api-request";
import { openUpgradeModal } from "./shared/upgrade-modal";
import { enforceLogin } from "./util";

export const NewProject: m.Component<{}> = {
  oninit() {
    if (!accountState.loggedInUser) {
      return enforceLogin();
    }

    const subscription = accountState.loggedInUser.subscription;
    if (subscription.plan === "Free" && subscription.projectsRemaining === 0) {
      m.route.set("/dashboard", {}, { replace: true });
      openUpgradeModal("projects");
      return;
    }

    const queryStringObject = m.parseQueryString(window.location.search);
    let folderId: string | undefined;
    if (typeof queryStringObject.folder === "string") {
      folderId = queryStringObject.folder;
    }

    const createNewProject = async () => {
      const responseData = await apiRequest("createProject", { folderId });
      if (responseData.success) {
        const projectId = responseData.projectId;
        const url = `/@${accountState.loggedInUser?.username}/${projectId}`;
        window.history.replaceState({}, "", url);
        window.location.reload();
      }
    };
    createNewProject();
  },
  view() {
    return null;
  },
};
